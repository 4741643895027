import React, {useState} from 'react';

import {SectionNav} from '@/jskit/react/SectionNav';
import {ComponentHistorySection} from '@/status_pages/display/History/sections/ComponentHistorySection';
import {PastIncidentsSection} from '@/status_pages/display/History/sections/PastIncidentsSection';
import {PastMaintenanceSection} from '@/status_pages/display/History/sections/PastMaintenanceSection';
import {HistoryOverviewSection} from '@/status_pages/display/History/sections/HistoryOverviewSection';
import {extractLastIncidentUpdateTime, compareIncidentsByLastUpdateFn} from '@/status_pages/display/Utils';

export enum HistoryTabs {
  HISTORY_OVERVIEW,
  PAST_INCIDENTS,
  PAST_MAINTENANCE,
  COMPONENT_HISTORY,
}

export interface HistoryProps {
  statuspage: any;
  componentStatusRank: any;
  componentStatusChoices: any;
  historyStartDate: object;
  historyEndDate: object;
}

export const History = ({
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  historyStartDate,
  historyEndDate,
}: HistoryProps) => {
  const [currentTab, setCurrentTab] = useState(HistoryTabs.HISTORY_OVERVIEW);
  const allTabs = [];
  if (statuspage.show_past_incidents || statuspage.show_component_history) {
    allTabs.push([HistoryTabs.HISTORY_OVERVIEW, 'History Overview']);
  }
  if (statuspage.show_past_incidents) {
    allTabs.push([HistoryTabs.PAST_INCIDENTS, 'Past Incidents']);
    allTabs.push([HistoryTabs.PAST_MAINTENANCE, 'Past Maintenance']);
  }
  if (statuspage.show_component_history) {
    allTabs.push([HistoryTabs.COMPONENT_HISTORY, 'Component History']);
  }
  if (!allTabs.length) {
    return false;
  }
  const allIncidents = (statuspage.past_incidents || []).map((incident) =>
    Object.assign({}, incident, {lastUpdated: extractLastIncidentUpdateTime(incident)})
  );
  const pastIncidents = allIncidents
    .filter((incident) => incident.incident_type === 'INCIDENT')
    .sort(compareIncidentsByLastUpdateFn);
  const pastMaintenance = allIncidents
    .filter((incident) => incident.incident_type === 'SCHEDULED_MAINTENANCE')
    .sort(compareIncidentsByLastUpdateFn);

  return (
    <SectionNav
      sectionIds={allTabs}
      currentSectionId={currentTab}
      navClass="nav nav-tabs-plain mb-4"
      onSectionNav={setCurrentTab}
    >
      <HistoryOverviewSection
        currentSectionId={currentTab}
        statuspage={statuspage}
        incidents={allIncidents}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
        historyStartDate={historyStartDate}
        historyEndDate={historyEndDate}
      />
      <PastIncidentsSection
        currentSectionId={currentTab}
        incidents={pastIncidents}
        componentStatusRank={componentStatusRank}
      />
      <PastMaintenanceSection
        currentSectionId={currentTab}
        incidents={pastMaintenance}
        componentStatusRank={componentStatusRank}
      />
      <ComponentHistorySection
        currentSectionId={currentTab}
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
        historyStartDate={historyStartDate}
        historyEndDate={historyEndDate}
      />
    </SectionNav>
  );
};
