import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import Utils from '../../jskit/general/Utils';
import CheckBox from '../../jskit/react/forms/CheckBox';
import Formatter from '../../jskit/general/Formatter';
import ReactUtils from '../../jskit/react/ReactUtils';
import BulkActionsMenu from './BulkActionsMenu';
import {ConfirmationModal} from '@/devices/services/ConfirmationModal';

export default class ButtonBar extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const savedFilters = Utils.loadFromSessionStorage('services_flt');
    this.state = {
      filters: savedFilters || this._clearFilters(),
      search: '',
      showPauseModal: false,
      showResumeModal: false,
    };

    this.debouncedTriggerFilterChanged = _.debounce(this.triggerFilterChanged, 750);
  }

  componentDidMount() {
    const $dropdown = $(this.refs.buttonBarTagsDropdown);

    $dropdown.find('.dropdown-menu').on('click', function () {
      $dropdown.data('preventClose', true);
    });

    $dropdown.on('hide.bs.dropdown', function (e) {
      if ($dropdown.data('preventClose')) {
        e.preventDefault();
        $dropdown.data('preventClose', false);
      }
    });
  }

  componentWillUnmount() {
    const $dropdown = $(this.refs.buttonBarTagsDropdown);
    $dropdown.find('.dropdown-menu').off('click');
    $dropdown.off('hide.bs.dropdown');
  }

  _clearFilters() {
    return {
      state: [],
      monitoring_service_type: '',
      tags: [],
    };
  }

  setFiltersAndSearch(filters, search) {
    Utils.saveToSessionStorage('services_flt', undefined);
    filters = _.extend(this._clearFilters(), _.pick(filters, 'state', 'monitoring_service_type', 'tags'));
    search = (search && search.toString()) || '';
    this.setState({filters: filters, search: search});
  }

  getQueryFilter() {
    const query = _.clone(this.state);

    // Remove empty filters so we don't filter on blanks
    query.filters = _.omit(query.filters, function (v) {
      return !v || v.length === 0;
    });
    return query;
  }

  handleStateFilterClick(state) {
    var newStateFilter = this.state.filters.state.concat();
    const idx = newStateFilter.indexOf(state);
    if (idx === -1) {
      newStateFilter.push(state);
    } else {
      newStateFilter.splice(idx, 1);
    }
    var filters = Object.assign({}, this.state.filters, {state: newStateFilter});
    this.setState({filters: filters}, this.triggerFilterChanged);
  }

  handleTypeFilterChange(key, e) {
    e.preventDefault();
    const newValue = this.state.filters.monitoring_service_type === key ? '' : key;
    const filters = Object.assign({}, this.state.filters, {monitoring_service_type: newValue});
    this.setState({filters: filters}, this.triggerFilterChanged);
  }

  handleTagFilterChange(tag, e) {
    e.preventDefault();
    e.stopPropagation();
    const currentTags = this.state.filters.tags || [];
    let filterVal = [];

    if (tag) {
      if (currentTags.indexOf(tag) >= 0) {
        filterVal = _.without(currentTags, tag);
      } else {
        filterVal = currentTags.concat([tag]);
      }
    }

    var filters = Object.assign({}, this.state.filters, {tags: filterVal});
    this.setState({filters: filters}, this.triggerFilterChanged);
  }

  handleSearchChange(e) {
    this.setState({search: e.target.value}, this.debouncedTriggerFilterChanged);
  }

  handleFiltersClear(e) {
    this.setState({filters: this._clearFilters(), search: ''}, this.triggerFilterChanged);
  }

  triggerFilterChanged() {
    Utils.saveToSessionStorage('services_flt', this.state.filters);
    if (this.props.onFilterChange) {
      this.props.onFilterChange();
    }
  }

  isFilterSet() {
    const f = this.state.filters;
    return this.state.search || f.state.length > 0 || f.tags.length > 0 || f.monitoring_service_type !== '';
  }

  handlePauseCancelClick() {
    if (this.state.showPauseModal) {
      this.setState({showPauseModal: false});
    }
  }

  handleResumeCancelClick() {
    if (this.state.showResumeModal) {
      this.setState({showResumeModal: false});
    }
  }

  render() {
    const stateClass = (state) =>
      ReactUtils.cssClass('btn btn-toggle', {
        ['active']: this.state.filters.state && this.state.filters.state.indexOf(state) !== -1,
      });
    const checkTypeFilterIsActive = this.state.filters.monitoring_service_type && this.props.typesFilter;
    var checkTypes = [];
    for (const g in this.props.typesFilter) {
      const items = this.props.typesFilter[g];
      checkTypes = checkTypes.concat(items);
    }
    const checkTypeName =
      checkTypeFilterIsActive && checkTypes.find((v) => v[0] === this.state.filters.monitoring_service_type)[1];

    let title = this.props.typesFilter ? (checkTypeName || '') + ' Checks' : '';
    if (this.props.groupCheckInfo) {
      title = (
        <a href={this.props.defaultDrillDownURL + this.props.groupCheckInfo.id} title="Go to Check Analysis page">
          {'Group: ' + this.props.groupCheckInfo.public_name_tuple[0]}
          <i className="fas fa-long-arrow-alt-right fa-lg align-middle ml-2" />
        </a>
      );
    }
    const handlePauseClick = () => {
      if (this.props.numSelected > 1) {
        this.setState({showPauseModal: true});
      } else {
        this.props.onPauseClick();
      }
    };

    const handleConfirmPause = () => {
      this.setState({showPauseModal: false});
      this.props.onPauseClick();
    };
    const handleResumeClick = () => {
      if (this.props.numSelected > 1) {
        this.setState({showResumeModal: true});
      } else {
        this.props.onResumeClick();
      }
    };

    const handleConfirmResume = () => {
      this.setState({showResumeModal: false});
      this.props.onResumeClick();
    };

    return (
      <React.Fragment>
        <div className="heading-container d-block d-sm-flex mb-4">
          <h1 className="heading mr-4">{title}</h1>
          <div className="form-inline">
            <div className="input-group mr-sm-3 mb-1 mb-sm-0">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handleSearchChange}
                className={ReactUtils.cssClass('form-control', {highlight: this.state.search})}
                placeholder="Search"
              />
            </div>
            {this.renderAddButton()}
          </div>
        </div>

        <div id="buttonBar" className="d-flex flex-wrap align-items-center mb-3">
          <BulkActionsMenu
            numSelected={this.props.numSelected}
            onPauseClick={handlePauseClick}
            onResumeClick={handleResumeClick}
            onBulkIntervalClick={this.props.onBulkIntervalClick}
            onBulkLocationClick={this.props.onBulkLocationClick}
            onBulkContactClick={this.props.onBulkContactClick}
            onRetryAndSensitivityClick={this.props.onRetryAndSensitivityClick}
            onBulkSLAClick={this.props.onBulkSLAClick}
            onBulkEscalationsClick={this.props.onBulkEscalationsClick}
            onBulkMaintenanceClick={this.props.onBulkMaintenanceClick}
            onBulkTagsClick={this.props.onBulkTagsClick}
            onBulkVersionClick={this.props.onBulkVersionClick}
            onBulkCreateGroupClick={this.props.onBulkCreateGroupClick}
            onBulkAddToGroupClick={this.props.onBulkAddToGroupClick}
            onDeleteClick={this.props.onDeleteClick}
          />
          <ConfirmationModal
            onConfirm={handleConfirmPause}
            onCancel={this.handlePauseCancelClick}
            showModal={this.state.showPauseModal}
          >
            <span>
              Are you sure you want to <b>Pause</b> these {this.props.numSelected} checks?
            </span>
          </ConfirmationModal>
          <ConfirmationModal
            onConfirm={handleConfirmResume}
            onCancel={this.handleResumeCancelClick}
            showModal={this.state.showResumeModal}
          >
            <span>
              Are you sure you want to <b>Resume</b> these {this.props.numSelected} checks?
            </span>
          </ConfirmationModal>
          <div className="mx-sm-auto"></div>
          <div className="mr-3 mb-2">
            <span className="d-inline-block mr-3 mb-1">Filter</span>
            <div className="btn-group btn-group-sm">
              <button onClick={this.handleStateFilterClick.bind(null, 'up')} type="button" className={stateClass('up')}>
                <span className="indicator badge-up"></span> Up <small>({this.props.totals.up})</small>
              </button>
              <button
                onClick={this.handleStateFilterClick.bind(null, 'down')}
                type="button"
                className={stateClass('down')}
              >
                <span className="indicator badge-down"></span> Down <small>({this.props.totals.down})</small>
              </button>
              <button
                onClick={this.handleStateFilterClick.bind(null, 'paused')}
                type="button"
                className={stateClass('paused')}
              >
                <span className="indicator badge-light"></span> Paused <small>({this.props.totals.paused})</small>
              </button>
              <button
                onClick={this.handleStateFilterClick.bind(null, 'maintenance')}
                type="button"
                className={stateClass('maintenance')}
                title="Under Maintenance"
              >
                <span className="indicator badge-maintenance"> </span> Maint.{' '}
                <small>({this.props.totals.maintenance})</small>
              </button>
            </div>
          </div>
          {this.renderTagsFilter()}
          {this.renderCheckTypeFilter(checkTypeFilterIsActive, checkTypeName)}
          <div className="mb-2">
            <button className="btn btn-sm btn-white" disabled={!this.isFilterSet()} onClick={this.handleFiltersClear}>
              <i className="fa fa-times"></i> Clear
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderAddButton() {
    if (!this.props.hasWriteAccess) {
      return null;
    }

    return (
      <div className="btn-group">
        <button
          data-wizard="services-add-new"
          type="button"
          onClick={this.props.onAddClick}
          className="btn btn-primary dropdown-toggle-split-left"
        >
          Add New
        </button>
        <button
          type="button"
          className="btn btn-primary dropdown-toggle dropdown-toggle-split dropdown-toggle-split-right"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <a href="#" onClick={this.props.onAddClick} className="dropdown-item">
            Add New Check
          </a>
          <a href={this.props.domainHealthURL} className="dropdown-item">
            Monitor Entire Site
          </a>
          <a href={this.props.bulkImportURL} className="dropdown-item">
            Bulk Import/Export Checks
          </a>
          <a href="#" onClick={this.props.onManageTagsClick} className="dropdown-item">
            Manage Tags
          </a>
        </div>
      </div>
    );
  }

  renderCheckTypeFilter(filterIsActive, typeName) {
    return (
      <div className="mb-2 mr-3">
        <div className="dropdown">
          <button
            id="button-bar-check-type"
            type="button"
            className={ReactUtils.cssClass('btn btn-sm btn-toggle dropdown-toggle', {active: filterIsActive})}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {filterIsActive ? typeName : 'All Checks'}
          </button>
          <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right" aria-labelledby="button-bar-check-type">
            {this.renderGroupedOptionList(
              '--- All Checks ---',
              this.props.typesFilter,
              this.state.filters.monitoring_service_type,
              this.handleTypeFilterChange
            )}
          </div>
        </div>
      </div>
    );
  }

  renderTagsFilter() {
    const numTagsActive = this.state.filters.tags && this.state.filters.tags.length;
    const tagsAreActive = !!numTagsActive;
    const tags = (this.props.tags || []).slice(0, this.props.maxTags);
    var choices = tags.map(function (tag) {
      return [tag.tag, tag.tag];
    });

    return (
      <div className="mr-3 mb-2">
        <div ref="buttonBarTagsDropdown" className="dropdown">
          <button
            id="button-bar-tags"
            type="button"
            style={{minWidth: '80px'}}
            className={ReactUtils.cssClass('btn btn-sm btn-toggle dropdown-toggle', {active: tagsAreActive})}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {tagsAreActive ? numTagsActive + ' ' + Formatter.pluralize(numTagsActive, 'Tag', 'Tags') : 'All Tags'}
          </button>
          <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right" aria-labelledby="button-bar-tags">
            {this.renderOptionList('All Tags', choices, this.state.filters.tags, this.handleTagFilterChange, true)}
            {tags.length >= this.props.maxTags ? (
              <div className="dropdown-item text-muted">
                <em>Limited to {this.props.maxTags}. Use Search to filter by other tags.</em>
              </div>
            ) : null}
            <div className="dropdown-divider"></div>
            <a key="mt" href="#" onClick={this.props.onManageTagsClick} className="dropdown-item">
              Manage Tags
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderOptionList(allLabel, options, selected, clickFn, useCheckboxes) {
    if (!options) {
      return null;
    }

    if (!selected) {
      selected = [];
    } else if (!_.isArray(selected)) {
      selected = [selected];
    }

    var finalOptions = [].concat(
      this.makeOptions('all', [['', allLabel]], selected, clickFn, useCheckboxes),
      <div key="at" className="dropdown-divider"></div>,
      this.makeOptions('', options, selected, clickFn, useCheckboxes)
    );
    return finalOptions;
  }

  renderGroupedOptionList(allLabel, groups, selected, clickFn) {
    if (!groups) {
      return null;
    }

    if (!selected) {
      selected = [];
    } else if (!_.isArray(selected)) {
      selected = [selected];
    }

    var finalOptions = [];
    finalOptions.push(this.makeOptions('', [['', allLabel]], selected, clickFn));
    for (const group in groups) {
      finalOptions.push(<div key={'dvd-' + group} className="dropdown-divider"></div>);
      finalOptions.push(
        <h6 key={'hdr-' + group} className="dropdown-header">
          {group}
        </h6>
      );
      finalOptions.push(this.makeOptions(group, groups[group], selected, clickFn));
    }
    return finalOptions;
  }

  makeOptions(group, options, selected, clickFn, useCheckboxes) {
    return options.map((v, i) => {
      if (useCheckboxes) {
        return (
          <div key={group + i} className="dropdown-item" onClick={clickFn.bind(null, v[0])}>
            <CheckBox
              labelText={v[1]}
              value={(!selected.length && v[0] === '') || selected.indexOf(v[0]) !== -1}
              onChange={clickFn.bind(null, v[0])}
            />
          </div>
        );
      } else {
        return (
          <a
            key={group + i}
            href="#"
            onClick={clickFn.bind(null, v[0])}
            className={ReactUtils.cssClass('dropdown-item', {active: selected.indexOf(v[0]) !== -1})}
          >
            {v[1]}
          </a>
        );
      }
    });
  }
}
