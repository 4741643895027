import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/CurrentStatus/CurrentStatus';
import IncidentsList from '@/status_pages/display/IncidentsList';
import {EmptySection} from '@/status_pages/display/EmptySection';

export interface ActiveIncidentsSectionProps {
  currentSectionId: CurrentStatusTabs;
  componentStatusRank: any;
  incidents: any;
}

export const ActiveIncidentsSection = ({
  currentSectionId,
  componentStatusRank,
  incidents,
}: ActiveIncidentsSectionProps) => {
  return (
    <SectionNavContent sectionId={CurrentStatusTabs.ACTIVE_INCIDENTS} currentSectionId={currentSectionId}>
      {incidents?.length > 0 ? (
        <IncidentsList componentStatusRank={componentStatusRank} incidents={incidents} />
      ) : (
        <EmptySection>
          There are no <strong>Active Incidents</strong>
        </EmptySection>
      )}
    </SectionNavContent>
  );
};
