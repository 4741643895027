'use strict';

import React from 'react';

export default class GlobalStatus extends React.PureComponent {
  render() {
    const sp = this.props.statuspage;

    if (sp.global_is_operational) {
      return (
        <div className="mb-4">
          <div className="global-status up">All systems operational</div>
          {sp.contact_email ? (
            <small className="text-muted font-12">
              Experiencing an issue? <a href={'mailto:' + sp.contact_email}>Contact us</a>
            </small>
          ) : null}
        </div>
      );
    }

    if (!sp.global_is_operational && sp.has_components_under_critical_state) {
      return (
        <div className="mb-4">
          <div className="global-status down">Some systems are experiencing issues</div>
        </div>
      );
    }

    if (!sp.global_is_operational && sp.has_components_under_maintenance_state) {
      return (
        <div className="mb-4">
          <div className="global-status maintenance">Some systems are under maintenance</div>
        </div>
      );
    }
  }
}

GlobalStatus.defaultProps = {
  statuspage: null,
};
