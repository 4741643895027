import React from 'react';
import classnames from 'classnames';

const SubscriptionDurationPicker = ({className, isAnnual, onChange, tooltip, disabled}) => (
  <div className={className} data-toggle="tooltip" title={tooltip}>
    <div className="btn-group btn-group-toggle d-flex" data-toggle="button">
      <button
        type="button"
        className={classnames('btn', {
          ButtonInactive: isAnnual,
          ButtonActive: !isAnnual,
          'text-strikethrough': isAnnual && disabled,
        })}
        disabled={disabled}
        onClick={onChange}
      >
        MONTHLY
      </button>
      <button
        type="button"
        className={classnames('btn', {
          ButtonInactive: !isAnnual,
          ButtonActive: isAnnual,
          'text-strikethrough': !isAnnual && disabled,
        })}
        disabled={disabled}
        onClick={onChange}
      >
        YEARLY
      </button>
      {!disabled && isAnnual ? (
        <p className="HelperText position-absolute">
          <b>2-months free</b>
          <br />
          with Annual Plans!
        </p>
      ) : (
        ''
      )}
    </div>
  </div>
);

export default SubscriptionDurationPicker;
