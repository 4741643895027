import React, {useState} from 'react';
import {SectionNav} from '@/jskit/react/SectionNav';
import {ActiveIncidentsSection} from './sections/ActiveIncidentsSection';
import {StatusOverviewSection} from './sections/StatusOverviewSection';
import {ActiveMaintenanceSection} from './sections/ActiveMaintenanceSection';
import {ScheduledMaintenanceSection} from './sections/ScheduledMaintenanceSection';
import {ComponentCurrentStatusSection} from './sections/ComponentCurrentStatusSection';
import {extractLastIncidentUpdateTime, compareIncidentsByLastUpdateFn} from '@/status_pages/display/Utils';

export enum CurrentStatusTabs {
  STATUS_OVERVIEW,
  ACTIVE_INCIDENTS,
  ACTIVE_MAINTENANCE,
  SCHEDULED_MAINTENANCE,
  COMPONENT_CURRENT_STATUS,
}

export interface CurrentStatusProps {
  statuspage: any;
  componentStatusRank: any;
  componentStatusChoices: any;
  metricDatapointsURL: string;
}

export const CurrentStatus = ({
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  metricDatapointsURL,
}: CurrentStatusProps) => {
  const [currentTab, setCurrentTab] = useState(CurrentStatusTabs.STATUS_OVERVIEW);
  const allTabs = [[CurrentStatusTabs.STATUS_OVERVIEW, 'Status Overview']];
  if (statuspage.show_active_incidents) {
    allTabs.push([CurrentStatusTabs.ACTIVE_INCIDENTS, 'Active Incidents']);
    allTabs.push([CurrentStatusTabs.ACTIVE_MAINTENANCE, 'Active Maintenance']);
    allTabs.push([CurrentStatusTabs.SCHEDULED_MAINTENANCE, 'Scheduled Maintenance']);
  }
  allTabs.push([CurrentStatusTabs.COMPONENT_CURRENT_STATUS, 'Component Current Status']);
  const allIncidents = statuspage.active_incidents.map((incident) =>
    Object.assign({}, incident, {lastUpdated: extractLastIncidentUpdateTime(incident)})
  );
  const activeIncidents = allIncidents
    .filter((incident) => incident.incident_type === 'INCIDENT')
    .sort(compareIncidentsByLastUpdateFn);
  const activeMaintenance = allIncidents
    .filter((incident) => incident.incident_type === 'SCHEDULED_MAINTENANCE')
    .sort(compareIncidentsByLastUpdateFn);
  const upcomingMaintenance = statuspage.upcoming_maintenance;
  return (
    <SectionNav
      sectionIds={allTabs}
      currentSectionId={currentTab}
      navClass="nav nav-tabs-plain mb-4"
      onSectionNav={setCurrentTab}
    >
      <StatusOverviewSection
        currentSectionId={currentTab}
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
        metricDatapointsURL={metricDatapointsURL}
      />
      <ActiveIncidentsSection
        currentSectionId={currentTab}
        componentStatusRank={componentStatusRank}
        incidents={activeIncidents}
      />
      <ActiveMaintenanceSection
        currentSectionId={currentTab}
        componentStatusRank={componentStatusRank}
        incidents={activeMaintenance}
      />
      <ScheduledMaintenanceSection
        currentSectionId={currentTab}
        componentStatusRank={componentStatusRank}
        incidents={upcomingMaintenance}
      />
      <ComponentCurrentStatusSection
        currentSectionId={currentTab}
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
      />
    </SectionNav>
  );
};
