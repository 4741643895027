'use strict';

import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import {CalculationType} from './Enums.jsx';

export default class GlobalMetrics extends React.PureComponent {
  render() {
    const sp = this.props.statuspage;
    let uptimePct, outageCnt, downtime;

    if (sp.global_metrics.uptime_pct === null || sp.global_metrics.uptime_pct === undefined) {
      uptimePct = outageCnt = downtime = 'n/a';
    } else {
      uptimePct = Formatter.percentage(sp.global_metrics.uptime_pct);
      outageCnt = sp.global_metrics.outages;
      downtime = Formatter.shortDuration(sp.global_metrics.downtime_secs, {
        roundSeconds: sp.uptime_calculation_type === CalculationType.BY_INCIDENTS,
      });
    }

    return (
      <div className="row history-global-metrics">
        <div className="col-sm-4 mb-2">
          <div>
            <h6>Global Uptime</h6>
            <span>{uptimePct}</span>
          </div>
        </div>
        <div className="col-sm-4 mb-2">
          <div>
            <h6>Outages</h6>
            <span>{outageCnt}</span>
          </div>
        </div>
        <div className="col-sm-4 mb-2">
          <div>
            <h6>Total Downtime</h6>
            <span>{downtime}</span>
          </div>
        </div>
      </div>
    );
  }
}

GlobalMetrics.defaultProps = {
  statuspage: null,
};
