'use strict';

import React, {useRef, useEffect} from 'react';
import Utils from '../../jskit/general/Utils';
import TextInput from '../../jskit/react/forms/TextInput';
import {IntlTelInputWidget} from '../../jskit/react/forms/IntlTelInput';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import {prepareFormLink, FormErrors} from '../../jskit/react/forms/FormHelpers';

const PAGE_TYPES = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
  SMS: 'SMS',
  RSS: 'RSS',
  WEBHOOK: 'WEBHOOK',
};

export default class SubscriptionCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.ALL_PAGES = [];

    if (this.props.allowSubscriptionEmail) {
      this.ALL_PAGES.push([PAGE_TYPES.EMAIL, <i className="fa-regular fa-envelope font-20" />]);
    }

    if (this.props.allowSubscriptionSlack) {
      this.ALL_PAGES.push([PAGE_TYPES.SLACK, <i className="fa-brands fa-slack font-20" />]);
    }

    if (this.props.allowSubscriptionRSS && this.props.rssLink) {
      this.ALL_PAGES.push([PAGE_TYPES.RSS, <i className="fa-regular fa-rss font-20" />]);
    }

    if (this.props.allowSubscriptionSMS) {
      this.ALL_PAGES.push([PAGE_TYPES.SMS, <i className="fa-regular fa-comment-sms font-20" />]);
    }

    if (this.props.allowSubscriptionWebhook) {
      this.ALL_PAGES.push([PAGE_TYPES.WEBHOOK, <i class="fa-regular fa-code font-20" />]);
    }

    const defaultPage = this.ALL_PAGES.length > 0 ? this.ALL_PAGES[0][0] : null;
    this.state = {
      formData: {
        subscription_type: defaultPage,
        subscription_target: '',
      },
      currentPage: defaultPage,
    };
    Utils.autoBindClass(this);
  }

  handlePageChange(page) {
    const formData = this.state.formData;
    formData.subscription_type = page;
    formData.subscription_target = '';
    this.props.clearValidationErrors();
    this.setState({formData, currentPage: page});
  }

  handleFormSubmit(e) {
    e.preventDefault();
    return this.props.onSubmit && this.props.onSubmit(this.state.formData);
  }

  render() {
    const formLink = prepareFormLink(this, 'formData', this.props.errors);
    const viewComponent =
      this.ALL_PAGES.length > 0 ? (
        <TabContainer
          pageList={this.ALL_PAGES}
          currentPage={this.state.currentPage}
          errors={this.props.errors}
          formLink={formLink}
          handlePageChange={this.handlePageChange}
          handleFormSubmit={this.handleFormSubmit}
          slackOAuthInitURL={this.props.slackOAuthInitURL}
          rssLink={this.props.rssLink}
        />
      ) : (
        false
      );

    return (
      <div className={'px-lg-3 py-lg-1'}>
        {viewComponent}
        <div className={'py-1 pb-2'}>
          <hr />
          By subscribing you agree to the&nbsp;
          <a target="_blank" href={this.props.termsConditionsURL}>
            Terms &amp; Conditions
          </a>
          &nbsp;and&nbsp;
          <a target="_blank" href={this.props.privacyPolicyURL}>
            Privacy Policy
          </a>
        </div>
      </div>
    );
  }
}

function TabContainer({
  pageList,
  currentPage,
  formLink,
  errors,
  handlePageChange,
  handleFormSubmit,
  rssLink,
  slackOAuthInitURL,
}) {
  const SectionComponent = SECTION_COMPONENTS[currentPage];
  return (
    <SectionNav
      sectionIds={pageList}
      currentSectionId={currentPage}
      navClass="nav nav-tabs-plain flex-nowrap nav-justified mb-2 d-inline-flex"
      onSectionNav={handlePageChange}
    >
      <SectionNavContent sectionId={currentPage} currentSectionId={currentPage}>
        <SectionComponent
          errors={errors}
          formLink={formLink}
          handleFormSubmit={handleFormSubmit}
          rssLink={rssLink}
          slackOAuthInitURL={slackOAuthInitURL}
        />
      </SectionNavContent>
    </SectionNav>
  );
}

const EmailPage = ({errors, formLink, handleFormSubmit}) => {
  const inputField = useRef(null);

  useEffect(() => {
    inputField.current.focus();
  }, []);
  return (
    <form onSubmit={handleFormSubmit}>
      <h2 className="font-18 py-3 mb-0">Subscribe via Email</h2>
      <div className="mt-2 mb-3">
        <FormErrors errors={errors} />
        <TextInput ref={inputField} formLink={formLink} fieldName="subscription_target" placeholder="Email address" />
      </div>
      <input
        className="btn btn-outline-primary btn-block"
        type="submit"
        disabled={!formLink.get('subscription_target')}
        value="Subscribe"
      />
    </form>
  );
};

const SMSPage = ({errors, formLink, handleFormSubmit}) => {
  return (
    <form onSubmit={handleFormSubmit}>
      <h2 className="font-18 py-3 mb-0">Subscribe via SMS</h2>
      <div className="mt-2 mb-3">
        <FormErrors errors={errors} />
        {/* The following div wrapper is necessary to keep the intl-tel-input
        library from stomping on the DOM which results in React errors */}
        <div>
          <IntlTelInputWidget formLink={formLink} fieldName="subscription_target" />
        </div>
      </div>
      <input
        className="btn btn-outline-primary btn-block"
        type="submit"
        disabled={!formLink.get('subscription_target')}
        value="Subscribe"
      />
    </form>
  );
};

const SlackPage = ({slackOAuthInitURL}) => {
  return (
    <>
      <h2 className="font-18 py-3 mb-0">Subscribe via Slack</h2>
      <a className="d-block w-100 btn-outline-primary btn mb-3 rounded-8" href={slackOAuthInitURL}>
        Connect a <i className="fa-brands fa-slack font-20" /> Slack Channel
      </a>
    </>
  );
};

const RSSPage = ({rssLink}) => {
  const handleCopyRssClick = (e) => {
    e.preventDefault();
    Utils.setClipboard(rssLink);
  };

  return (
    <>
      <h2 className="font-18 py-3 mb-0">Subscribe via RSS</h2>
      <div className="pb-3 mb-0">Copy the link below to:</div>
      <TextInput value={rssLink} readOnly={true} />
      <a className="d-block w-100 btn-outline-primary btn mb-3 rounded-8" onClick={handleCopyRssClick}>
        Copy Link
      </a>
    </>
  );
};

const WebhookPage = ({errors, formLink, handleFormSubmit}) => {
  const inputField = useRef(null);

  useEffect(() => {
    inputField.current.focus();
  }, []);
  return (
    <form onSubmit={handleFormSubmit}>
      <h2 className="font-18 py-3 mb-0">Subscribe via Webhook</h2>
      <div className="mt-2 mb-3">
        <FormErrors errors={errors} />
        <TextInput
          ref={inputField}
          formLink={formLink}
          fieldName="subscription_target"
          placeholder="The URL we should send to..."
        />
      </div>
      <input
        className="btn btn-outline-primary btn-block"
        type="submit"
        disabled={!formLink.get('subscription_target')}
        value="Subscribe"
      />
    </form>
  );
};

const SECTION_COMPONENTS = {
  [PAGE_TYPES.EMAIL]: EmailPage,
  [PAGE_TYPES.SMS]: SMSPage,
  [PAGE_TYPES.SLACK]: SlackPage,
  [PAGE_TYPES.RSS]: RSSPage,
  [PAGE_TYPES.WEBHOOK]: WebhookPage,
};
