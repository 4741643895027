'use strict';

import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import Incident from './Incident.jsx';

export default class PastIncidents extends React.PureComponent {
  render() {
    const {incidents} = this.props;
    return <>{this.renderPastIncidents()}</>;
  }

  renderPastIncidents() {
    const {incidents} = this.props;

    let pastIncidentsPerMonth = {};
    incidents.forEach((incident) => {
      const date = Formatter.localizeDate(incident.starts_at);
      const month = date.format('YYYYMM');

      if (!pastIncidentsPerMonth[month]) {
        pastIncidentsPerMonth[month] = {
          month: month,
          monthDisplay: date.format('MMMM YYYY'),
          incidents: [],
        };
      }

      pastIncidentsPerMonth[month].incidents.push(incident);
    });

    pastIncidentsPerMonth = Object.values(pastIncidentsPerMonth).sort((a, b) => (a.month < b.month ? 1 : -1));

    const renderedMonths = pastIncidentsPerMonth.map((month, i) => {
      return (
        <div key={month.month} className="mb-4">
          <div className="font-14 mb-2">
            {month.monthDisplay} ({month.incidents.length})
          </div>
          <div className="incident-list">
            {month.incidents.map((incident, j) => (
              <Incident key={j} incident={incident} componentStatusRank={this.props.componentStatusRank} />
            ))}
            <div className="incident-end"></div>
          </div>
        </div>
      );
    });
    return renderedMonths;
  }
}

PastIncidents.defaultProps = {
  componentStatusRank: null,
  incidents: null,
};
