import React from 'react';
import classnames from 'classnames';
import Formatter from '../../jskit/general/Formatter';
import {PlanDetails} from './PlanDetails';

type PlanProps = {
  subtitle: string;
  featureText: string;
  name: string;
  price: number;
  btnText: string;
  resetLocationsURL: string;
  unsupportedChecksURL: string;
  btnClass?: string;
  plan?: any;
  btnTooltip?: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  featured?: boolean;
  overLimit?: boolean;
  startHereText?: string;
};

const _buildPlanWarning = (name: string) =>
  `In order to subscribe to ${Formatter.capitalizeFirstLetter(
    name
  )} please adjust current check usage highlighted below.`;

const Plan = ({
  subtitle,
  featureText,
  name,
  plan,
  price,
  onClick,
  resetLocationsURL,
  unsupportedChecksURL,
  btnText,
  startHereText,
  btnClass,
  disabled,
  overLimit,
  featured,
}: PlanProps) => {
  const renderSubtitle = () => {
    if (name === 'CUSTOM') {
      return (
        <>
          Create a <b>Custom</b> monitoring plan <b>that supports only what you require,</b> nothing you don’t!
        </>
      );
    } else {
      return <b>{subtitle}</b>;
    }
  };
  const renderTitle = () => {
    if (name === 'CUSTOM') {
      return <>Your Plan, Your Way</>;
    } else {
      return Formatter.capitalizeFirstLetter(name);
    }
  };
  const renderPriceWithPromo = () => {
    if (featured) {
      return (
        <>
          <span className="badge badge-pill badge-primary text-uppercase font-12">25% off</span>
          <div className="FromPrefix">
            <del>{Formatter.shortCurrency(price)}</del>
          </div>
          <div className="Price">&nbsp;{Formatter.shortCurrency(price - price * 0.25 + 1)}/mo.</div>
        </>
      );
    } else {
      return (
        <>
          <div className="FromPrefix">From</div>
          <div className="Price">&nbsp;{Formatter.shortCurrency(price)}/mo.</div>
        </>
      );
    }
  };
  return (
    <div
      className={classnames('col px-1 PricingOptionCol', {
        'mt-6': !featured && !overLimit,
        featured: featured,
        'crown-over-limit-wrapper': overLimit,
      })}
    >
      {featured && !overLimit && (
        <div className="font-barlow font-weight-semibold py-2 text-white plan-crown crown-featured text-center font-18">
          {featureText}
        </div>
      )}
      {overLimit && (
        <div className="plan-crown d-flex">
          <div className="font-barlow text-white crown-over-limit text-center">
            <i className="far fa-solid fa-gauge-circle-minus text-brand-red" />
            {_buildPlanWarning(name)}
          </div>
        </div>
      )}
      <div className={classnames(`PricingOptionHeader ${name}`, {FeaturedBorder: featured})}>
        <h2>{renderTitle()}</h2>
        <div className="subtitle">{renderSubtitle()}</div>
      </div>
      <div className={classnames(`PricingOptionDetail ${name}`, {'FeaturedBorder FeaturedBorderBase': featured})}>
        <div className={`d-flex p-3 ${name}-price`}>
          <div className="row position-relative flex-nowrap">{renderPriceWithPromo()}</div>
        </div>
        <div className={classnames(`PricingOptionDetail ${name}`, {'FeaturedBorder FeaturedBorderBase': featured})}>
          <div title={overLimit ? _buildPlanWarning(name) : undefined} data-toggle="tooltip">
            <button
              className={`btn w-100 ${disabled || overLimit ? 'btn-muted' : btnClass ?? 'btn-success'}`}
              onClick={onClick}
              disabled={disabled || overLimit}
            >
              {btnText ?? 'Subscribe'}
            </button>
          </div>
          <div className="PricingOptionIntroText">{startHereText ?? 'Start here and grow:'}</div>
          <hr />
          <PlanDetails
            name={name}
            plan={plan && plan.def}
            limits={plan && plan.limits}
            resetLocationsURL={resetLocationsURL}
            unsupportedChecksURL={unsupportedChecksURL}
          />
        </div>
      </div>
    </div>
  );
};

export default Plan;
