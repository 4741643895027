'use strict';

import React from 'react';
import Utils from '../jskit/general/Utils';
import _ from 'underscore';
import CheckItem from './CheckItem.jsx';

export default class MonitoringChecks extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
  }

  handleAddCheck(checkType) {
    const {checksURL} = this.props;
    window.location = checksURL + '?pop=true&gobackoncancel=true&monitoring_service_type=' + checkType;
  }

  renderCheckItem(checkItem) {
    const checkSlug = checkItem[0];
    const checkName = checkItem[1];

    return (
      <CheckItem
        slug={checkSlug}
        key={checkSlug}
        name={checkName}
        count={this.props.checkUsage[checkSlug]}
        helpText={this.props.serviceDefs[checkSlug].help_text}
        supportURL={this.props.serviceDefs[checkSlug].support_link}
        withAddNew={this.props.hasWriteAccess}
        onAdd={this.handleAddCheck}
      />
    );
  }

  renderChecksGroup(title, checkItems) {
    const items = checkItems.map((item) => this.renderCheckItem(item));
    const iconClass = 'fas fa-fw ' + this.props.groupsDefinition[title].icon;
    const tooltip = this.props.groupsDefinition[title].tooltip;
    return (
      <div className="col-md col-sm-12 pt-4" key={title}>
        <div className="check-title" data-tip={tooltip}>
          <i className={iconClass}></i> <h5 className="d-inline-block">{title}</h5>
        </div>
        {items}
      </div>
    );
  }

  render() {
    const checkGroups = _.pairs(this.props.checkTypes).map((item) => this.renderChecksGroup(item[0], item[1]));
    return (
      <div className="bg-white rounded-8" id="launchpad-checks">
        <div className="container mw-100">
          <h4>Monitoring checks</h4>
          <small className="text-muted">
            {/* eslint-disable-next-line max-len */}A Check monitors a URL or IP address at intervals as low as
            1-minute, and issues alerts upon downtime detection
          </small>
          <div className="row mb-2">{checkGroups}</div>
        </div>
      </div>
    );
  }
}
