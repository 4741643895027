'use strict';

import React from 'react';
import {default as QuickActions, Action} from './QuickActions';
import Formatter from '../../jskit/general/Formatter';
import _ from 'underscore';

type Service = {
  id: number;
  is_paused: boolean;
  monitoring_service_type: string;
};

type ExtraListProps = {
  drillDownURLs: Record<string, string>;
  defaultDrillDownURL: string;
  alertsURL: string;
  analysisURL: string;
  rumManagePatternsURL: string;
  transactionRecorderURL: string;
  auditLogURL: string;
};

type ServiceItemActionsProps = {
  item: Service;
  hasWriteAccess: boolean;
  hasAdminAccess: boolean;
  extraListProps: ExtraListProps;
  onEdit: React.MouseEventHandler;
  onMaintenance: React.MouseEventHandler;
  onResume: React.MouseEventHandler;
  onPause: React.MouseEventHandler;
  onDuplicate: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
};

const ServiceItemActions = (props: ServiceItemActionsProps) => {
  const getActions = () => {
    const actions = [];
    if (props.hasWriteAccess) {
      actions.push({
        onClick: props.onEdit,
        className: 'fa-pen-to-square',
        text: 'Edit Check',
      });
      actions.push({
        className: 'fa-calendar-plus',
        onClick: props.onMaintenance,
        text: 'Set maintenance',
      });
      const isPaused = props.item.is_paused;
      const pauseResumeIcon = isPaused ? 'fa-play' : 'fa-pause';
      actions.push({
        className: `d-lg-none d-xl-inline ${pauseResumeIcon}`,
        text: isPaused ? 'Resume' : 'Pause',
        onClick: isPaused ? props.onResume : props.onPause,
      });
    }

    const links = getLinks();
    return actions.concat(links);
  };

  const getLinks = () => {
    const urlMap = _.clone(props.extraListProps.drillDownURLs);
    delete urlMap['GROUP'];
    const item = props.item;
    const reportLink = Formatter.serviceReportURL(item, props.extraListProps.defaultDrillDownURL, urlMap);
    const groupDetailsLink = props.extraListProps.drillDownURLs['GROUP'] + item.id;
    const links: Action[] = [
      {
        text: 'View Check Analysis',
        href: reportLink,
        className: 'fa-file-chart-pie',
      },
      {
        text: 'Alerts',
        className: 'fa-circle-exclamation',
        href: props.extraListProps.alertsURL + item.id,
      },
    ];

    if (item.monitoring_service_type === 'PAGESPEED') {
      links[0].href = props.extraListProps.defaultDrillDownURL + item.id;
      // insert pagespeed report in 2 position
      links.splice(1, 0, {
        text: 'Pagespeed Report',
        href: reportLink,
      });
    }

    if (item.monitoring_service_type !== 'GROUP') {
      links.push({
        href: props.extraListProps.analysisURL.replace('0000', item.id.toString()),
        text: 'Analysis',
        endsBlock: props.hasWriteAccess,
        className: 'fa-magnifying-glass-chart',
      });
    } else {
      links.unshift({
        text: 'Details',
        href: groupDetailsLink,
        endsBlock: props.hasWriteAccess,
      });
    }
    if (props.hasWriteAccess) {
      if (item.monitoring_service_type === 'TRANSACTION') {
        links.push({
          text: 'Edit in Recorder',
          href: props.extraListProps.transactionRecorderURL + '?service=' + item.id,
        });
      }

      links.push({
        text: 'Duplicate',
        onClick: props.onDuplicate,
      });
      links.push({
        text: 'Delete',
        onClick: props.onDelete,
        endsBlock: item.monitoring_service_type !== 'RUM' && props.hasAdminAccess,
      });

      if (item.monitoring_service_type === 'RUM') {
        links.push({
          text: 'Manage URL Patterns',
          href: props.extraListProps.rumManagePatternsURL + item.id,
          endsBlock: props.hasAdminAccess,
        });
      }
    }

    if (props.hasAdminAccess) {
      links.push({
        text: 'Audit Log',
        href: props.extraListProps.auditLogURL + '?model=Check&s=' + item.id,
      });
    }

    return links;
  };

  const actions = getActions();
  return <QuickActions dropdownMenuClassName="dropdown-menu-right" actions={actions} />;
};

export default ServiceItemActions;
