'use strict';

import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import QuickActions from '../../devices/services/QuickActions';

function _getEditCheckURL(editCheckURL, alert) {
  return editCheckURL.replace('0000', alert.service_id);
}

function _getAnalysisURL(analysisURL, alert) {
  return analysisURL.replace('0000', alert.service_id);
}

class Alert extends React.Component {
  render() {
    const ongoingText = this.props.service_is_paused ? 'check currently paused' : 'ongoing';
    let statusTitle = this.props.up_at ? 'Outage has been resolved.' : 'Outage is still occurring.';
    let statusClassName = 'status ' + (this.props.up_at ? 'status-up' : 'status-down');
    if (this.props.ignored) {
      statusClassName += ' status-ignored';
      statusTitle = 'Alert has been ignored.';
    }

    const ignoreText = this.props.ignored ? "Don't Ignore Alert" : 'Ignore This Alert';
    const actions = [];
    actions.push({
      text: 'Alert Details',
      onClick: this.props.onDetailsClick,
      className: 'fa-location-exclamation',
    });
    if (!this.props.detailsButtonOnly) {
      if (!this.props.service_is_deleted) {
        actions.push({
          href: _getAnalysisURL(this.props.analysisURL, this.props),
          className: 'fa-magnifying-glass-chart',
          text: 'Real Time Analysis',
          endsBlock: this.props.hasWriteAccess && this.props.service_is_deleted,
        });
      }

      if (this.props.hasWriteAccess && !this.props.service_is_deleted) {
        actions.push({
          href: _getEditCheckURL(this.props.editCheckURL, this.props),
          text: 'Edit Check',
          className: 'fa-pen-to-square',
          endsBlock: this.props.hasWriteAccess,
        });
      }

      if (this.props.hasWriteAccess) {
        actions.push({
          onClick: this.props.onIgnoreClick,
          className: 'fa-bell-slash',
          text: ignoreText,
        });
      }
    }

    return (
      <tr>
        <td>
          <i className={statusClassName} title={statusTitle} />
          <a href="#" onClick={this.props.onDetailsClick} className="d-block unstyled font-weight-semibold">
            {this.props.public_name_tuple[0]}
          </a>
          <a href="#" onClick={this.props.onDetailsClick} className="d-block unstyled text-muted mb-0">
            {this.props.public_name_tuple[1]}
          </a>
        </td>
        <td>{Formatter.longDateTime(this.props.created_at)}</td>
        <td>
          {this.props.up_at ? (
            <span>{Formatter.longDateTime(this.props.up_at)}</span>
          ) : (
            <em className="text-muted">{ongoingText}</em>
          )}
        </td>
        <td>{this.props.duration ? Formatter.shortDuration(this.props.duration) : null}</td>
        <td>{this.renderLocations(this.props.down_alerts)}</td>
        <td className="actions-right">
          <QuickActions dropdownMenuClassName="dropdown-menu-right" actions={actions} />
        </td>
      </tr>
    );
  }

  renderLocations(downAlerts) {
    let locations = downAlerts.map((a) => (a.monitoring_server ? a.monitoring_server.location : null));
    locations = locations.filter((l) => !!l);

    if (locations.length === 0) {
      return <em className="text-muted">n/a</em>;
    } else if (locations.length <= 3) {
      return locations.join(', ');
    } else {
      return locations[0] + ', ' + locations[1] + ' + ' + (locations.length - 2) + ' more';
    }
  }
}

export class AlertHistoryList extends React.Component {
  render() {
    if (!this.props.hasLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length === 0) {
      return <div className="mb-4">No outages found.</div>;
    }

    const nodes = this.props.items.map(
      function (item, index) {
        return (
          <Alert
            {...item}
            key={item.id}
            hasWriteAccess={this.props.hasWriteAccess}
            detailsButtonOnly={this.props.detailsButtonOnly}
            editCheckURL={this.props.editCheckURL}
            analysisURL={this.props.analysisURL}
            onDetailsClick={this.props.onDetailsClick.bind(null, index)}
            onIgnoreClick={this.props.onIgnoreClick ? this.props.onIgnoreClick.bind(null, index) : null}
          />
        );
      }.bind(this)
    );

    return (
      <div className="white-block white-block-border mb-4">
        <table className="table table-responsive-lg data-table data-table-with-status mb-0" role="grid">
          <thead>
            <tr>
              <th>Check</th>
              <th>From</th>
              <th>To</th>
              <th>Duration</th>
              <th>Locations</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </table>
      </div>
    );
  }
}
