import React from 'react';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import {prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import Cookie from '../../jskit/general/Cookie';
import ManageAddOns from './ManageAddOns.jsx';
import SubscriptionCheckoutPage from './SubscriptionCheckoutPage.jsx';
import ContactUs from './ContactUs';
import {PLAN_FIELD_PLURAL} from './PLAN_DEFS';

const SubscriptionPage = {
  ManageAddOns: 'manage-add-ons',
  Quote: 'quote',
  Checkout: 'checkout',
};

const SUBSCRIPTION_STORAGE = 'subscriptionPage';

const MAX_ADDED_VALUES_COST = 1000.0;

export default class SubscriptionController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    const minAddedValues = this._getMinAddedValues(this.props.minValues, this.props.includedValues);
    this.state = {
      page: SubscriptionPage.ManageAddOns,
      includedValues: this.props.includedValues,
      maxValues: this.props.maxValues,
      minAddedValues: minAddedValues,
      addedValues: minAddedValues,
      commonData: {
        annual_billing: this.props.isAnnual,
        promo_code: '',
      },
      validationErrors: null,
      checkoutErrors: null,
      calculated: {
        monthly_subtotal: 0.0,
        price_monthly: 0.0,
        base_price: 0.0,
        added_cost: 0.0,
        base_plan: '',
        discounts: [],
        units_prices: this.props.unitsPrices,
      },
      recaptchaRequired: false,
      recaptchaResponseToken: null,
    };

    const savedValues = Cookie.getJSONCookie(SUBSCRIPTION_STORAGE);
    if (savedValues && savedValues.addedValues) {
      this.state.addedValues = savedValues.addedValues;
    }

    this.throttledRecalculate = _.throttle(this.handleRecalculate, 200, {leading: false});
  }

  _getMinAddedValues(minValues, includedValues) {
    return _.mapObject(minValues, (v, k) => {
      const bundleSize = this.props.bundleSizes[k];
      const newVal = Math.ceil(Math.max(0, v - includedValues[k]) / bundleSize) * bundleSize;
      return Math.max(this.state ? this.state.addedValues[k] : 0.0, newVal);
    });
  }

  componentDidMount() {
    const {initialValues} = this.props;
    if (initialValues && this.props.isCustomer) {
      const addedValues = Object.assign({}, this.state.addedValues, initialValues);
      this.setState({addedValues: addedValues}, () => this.handleRecalculate());
    } else {
      this.handleRecalculate();
    }
  }

  getFormData() {
    // convert naturally boolean value to integer
    const addedValues = Object.assign({}, this.state.addedValues);
    const advancedMonitoringBundle = !!addedValues.advanced_monitoring_bundle;
    addedValues.advanced_monitoring_bundle = advancedMonitoringBundle ? 1 : 0;
    if (this.state.minAddedValues.advanced_monitoring_bundle || addedValues.advanced_monitoring_bundle === 0) {
      delete addedValues.advanced_monitoring_bundle;
    }
    return addedValues;
  }

  onNewData(data) {
    this.setState({calculated: data});
  }

  handleSubscriptionUpdate(paymentData, paymentFieldsErrorFn, finalizeSubmitFn) {
    this.setState({checkoutErrors: null, recaptcha_required: false});
    const data = {
      payment_data: paymentData,
      added_values: _.pick(this.getFormData(), (v) => v > 0),
      recaptcha_response_token: this.state.recaptchaResponseToken,
    };
    new Ajax().post({
      url: this.props.updateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          Cookie.clearCookie(SUBSCRIPTION_STORAGE);
          window.location = this.props.doneURL + '?action=subscription';
        } else {
          finalizeSubmitFn();
          if (data.fields.payment_fields) {
            paymentFieldsErrorFn(data.fields.payment_fields);
          }
          if (data.fields.__all__) {
            this.setState({
              checkoutErrors: data.fields.__all__,
              recaptchaRequired: data.fields.recaptcha_required || false,
              recaptchaResponseToken: null,
            });
          }
        }
      }.bind(this),
    });
  }

  handleRecalculate() {
    const addedValues = _.pick(this.getFormData(), (v) => v > 0);
    // if (_.isEmpty(addedValues)) {
    //   return;
    // }

    const data = {
      added_values: addedValues,
      extra_data: this.state.commonData,
    };
    this.setState({validationErrors: null});
    new Ajax().post({
      url: this.props.calculateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!_.isEmpty(data.data)) {
          this.onNewData(data.data);
        }
        if (!data.success) {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleValuesChange() {
    _.defer(() => Cookie.setJSONCookie(SUBSCRIPTION_STORAGE, {addedValues: this.state.addedValues}, 1));
    this.throttledRecalculate();
  }

  _isMaxHit() {
    // check maximum values before going to checkout
    if (this.state.calculated.added_cost > MAX_ADDED_VALUES_COST) {
      return true;
    }
    let maxHit = false;
    _.mapObject(this.state.addedValues, (v, k) => {
      const included = this.state.includedValues[k];
      // a hack coming from the backend; we consider included 10000 as infinite value
      const isInfiniteValue = included === 10000;
      const itemsNumber = parseInt(v) + included;
      const absMax = this.props.premiumLimits[k] || 10000;
      if (itemsNumber > absMax && !isInfiniteValue) {
        maxHit = true;
      }
    });
    return maxHit;
  }

  _buildCustomNeedsString() {
    return _.reduce(
      this.state.addedValues,
      (a, v, k) => {
        if (!v) {
          return a;
        }
        const totalValue = v + this.state.includedValues[k];
        const itemTitle = PLAN_FIELD_PLURAL[k];
        return a + (itemTitle + ': ' + totalValue + '\n');
      },
      ''
    );
  }

  _mustEnforceQuote() {
    if (!this.props.accountCanPurchase || !this.props.userCanPurchase) {
      return true;
    }
    return this._isMaxHit();
  }

  handleGoToCheckout(e) {
    e.preventDefault();
    const page = this._mustEnforceQuote() ? SubscriptionPage.Quote : SubscriptionPage.Checkout;
    _.debounce(() => {
      this.setState({page: page});
    }, 200)();
  }

  handleCheckoutCancel(e) {
    e.preventDefault();
    this.setState({page: SubscriptionPage.ManageAddOns});
  }

  render() {
    const addedValuesFormLink = prepareFormLink(this, 'addedValues');
    const commonDataFormLink = prepareFormLink(this, 'commonData', this.state.validationErrors);
    const quoteInitialData = {
      ...this.props.companyData,
      custom_monitoring_needs: this._buildCustomNeedsString(),
    };

    return (
      <>
        {this.state.page === SubscriptionPage.ManageAddOns && (
          <ManageAddOns
            pastDue={this.props.pastDue}
            plan={this.props.plan}
            accountUsageURL={this.props.accountUsageURL}
            sectionsDef={this.props.sections_def}
            bundleSizes={this.props.bundleSizes}
            unitsPrices={this.state.calculated.units_prices}
            maxValues={this.props.maxValues}
            accountType={this.props.accountType}
            isPrimary={this.props.isPrimary}
            calculatedValues={this.state.calculated}
            includedValues={this.props.includedValues}
            usedValues={this.props.usedValues}
            isCustomer={true}
            limits={this.props.limits}
            accountBillingPeriodIsAnnual={this.props.isAnnual}
            formLink={addedValuesFormLink}
            commonDataFormLink={commonDataFormLink}
            onValuesChange={this.handleValuesChange}
            isQuote={this._mustEnforceQuote()}
            customRequestURL={this.props.customRequestURL}
            userCanPurchase={this.props.userCanPurchase}
            accountCanPurchase={this.props.accountCanPurchase}
            handleGoToCheckout={this.handleGoToCheckout}
            doneURL={this.props.doneURL}
          />
        )}
        {this.state.page === SubscriptionPage.Quote && (
          <ContactUs
            initialValues={quoteInitialData}
            requestURL={this.props.customRequestURL}
            onCancel={() => this.setState({page: SubscriptionPage.ManageAddOns})}
            onSuccess={() => {
              Cookie.clearCookie(SUBSCRIPTION_STORAGE);
              window.location = this.props.doneURL + '?action=custom-request';
            }}
          />
        )}
        {this.state.page === SubscriptionPage.Checkout && (
          <SubscriptionCheckoutPage
            withTitle={true}
            unitsPrices={this.state.calculated.units_prices}
            bareUnitesPrices={this.props.unitsPrices}
            addedValues={this.state.addedValues}
            formLink={{get: () => this.props.isAnnual}}
            includedValues={this.state.includedValues}
            calculatedValues={this.state.calculated}
            braintreeToken={this.props.braintreeToken}
            isPrimary={this.props.isPrimary}
            isCustomer={true}
            isUpgrade={false}
            companyData={this.props.companyData}
            allowPromocode={false}
            checkoutErrors={this.state.checkoutErrors}
            paymentMethod={this.props.paymentMethod}
            billingInfo={this.props.billingInfo}
            uptimeBrandInfo={this.props.uptimeBrandInfo}
            onCancel={this.handleCheckoutCancel}
            onSubmit={this.handleSubscriptionUpdate}
            canPurchase={this.props.userCanPurchase}
            updateURL={this.props.updateURL}
            allCountries={this.props.allCountries}
            excludeStateCountries={this.props.excludeStateCountries}
            showStateDropdownForCountries={this.props.showStateDropdownForCountries}
            recaptchaSiteKey={this.props.recaptchaSiteKey}
            recaptchaRequired={this.state.recaptchaRequired}
            recaptchaSolved={this.state.recaptchaResponseToken !== null}
            recaptchaCallback={(token) => {
              this.setState({recaptchaResponseToken: token});
            }}
          />
        )}
      </>
    );
  }
}
