'use strict';

import React from 'react';
import moment from 'moment';
import Formatter from '../../jskit/general/Formatter';
import ReactUtils from '../../jskit/react/ReactUtils';
import ResponseTimeChart from '../../jskit/react/ResponseTimeChart';
import {AlertHistoryList} from '../../reports/common/AlertHistoryList';
import {AlertDetailsModal} from '../../reports/common/AlertDetailsModal';

export default class DashboardDisplay extends React.Component {
  showAlertModal() {
    this.refs.modal.showModal();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.dashboardDef.metrics_show_section ? this.renderGlobalMetrics() : null}
        {this.props.dashboardDef.services_show_section && this.props.hasAnyChecks ? this.renderChecks() : null}
        {this.props.dashboardDef.alerts_show_section && this.props.hasAnyChecks ? this.renderLatestOutages() : null}
      </React.Fragment>
    );
  }

  renderGlobalMetrics() {
    if (!this.props.hasLoaded) {
      return (
        <div id="dashboard-global-metrics" className="white-block white-block-border p-5">
          Loading...
        </div>
      );
    }

    const mt = this.props.globalMetrics;
    const na = <span className="text-muted">n/a</span>;
    const formatNumOutages = (n) => (n >= 100 ? '100+' : n.toString());
    const checkCountLabel = this.props.dashboardDef.metrics_for_all_checks ? 'total' : 'selected';

    return (
      <div id="dashboard-global-metrics" className="white-block white-block-border">
        <div className="row row-equal-height">
          <div className="col-sm-6 col-lg-3">
            <div className="p-4">
              <h6>
                <a title="Show all checks" href={this.props.servicesURL}>
                  <span>
                    {mt.checks.total} {checkCountLabel} checks
                  </span>
                  <i className="fas fa-long-arrow-alt-right fa-lg align-middle ml-2" />
                </a>
              </h6>
              <div className={ReactUtils.cssClass('metric', {danger: mt.checks.down > 0})}>
                <a title="Show all down checks" href={this.props.servicesURL + '?state=down'}>
                  <i />
                  Down
                </a>
                <a title="Show all down checks" href={this.props.servicesURL + '?state=down'}>
                  {mt.checks.down || 0}
                </a>
              </div>
              <div className="metric">
                <a title="Show all paused checks" href={this.props.servicesURL + '?state=paused'}>
                  <i />
                  Paused
                </a>
                <a title="Show all paused checks" href={this.props.servicesURL + '?state=paused'}>
                  {mt.checks.paused || 0}
                </a>
              </div>
              <div className="metric">
                <a title="Show all checks under maintenance" href={this.props.servicesURL + '?state=maintenance'}>
                  <i />
                  Maintenance
                </a>
                <a title="Show all checks under maintenance" href={this.props.servicesURL + '?state=maintenance'}>
                  {mt.checks.maintenance || 0}
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="p-4">
              <h6>
                <a title="Show all alerts" href={this.props.alertHistoryURL}>
                  <span>Outages</span>
                  <i className="fas fa-long-arrow-alt-right fa-lg align-middle ml-2" />
                </a>
              </h6>
              <div className="metric">
                <span>
                  <i />
                  Last 24h
                </span>
                <span>{formatNumOutages(mt.outages['1d'])}</span>
              </div>
              <div className="metric">
                <span>
                  <i />
                  Last 7d
                </span>
                <span>{formatNumOutages(mt.outages['7d'])}</span>
              </div>
              <div className="metric">
                <span>
                  <i />
                  Last 30d
                </span>
                <span>{formatNumOutages(mt.outages['30d'])}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="p-4">
              <h6>Response Time</h6>
              <div
                className={ReactUtils.cssClass(
                  'metric',
                  Formatter.responseTimeCSSClass(
                    mt.response_time.HTTP.response_time_avg,
                    mt.response_time.HTTP.response_time_sla
                  )
                )}
              >
                <a
                  title="Response time of HTTP checks for the last 15m. Click to show all HTTP checks."
                  href={this.props.servicesURL + '?monitoring_service_type=HTTP'}
                >
                  <i />
                  HTTP
                </a>
                {mt.response_time.HTTP.response_time_avg !== null ? (
                  // eslint-disable-next-line max-len
                  <a
                    title="Response time of HTTP checks for the last 15m. Click to show all HTTP checks."
                    href={this.props.servicesURL + '?monitoring_service_type=HTTP'}
                  >
                    {Formatter.autoDuration(mt.response_time.HTTP.response_time_avg)}
                  </a>
                ) : (
                  na
                )}
              </div>
              <div
                className={ReactUtils.cssClass(
                  'metric',
                  Formatter.responseTimeCSSClass(
                    mt.response_time.TRANSACTION.response_time_avg,
                    mt.response_time.TRANSACTION.response_time_sla
                  )
                )}
              >
                {/* eslint-disable-next-line max-len */}
                <a
                  title="Response time of Transaction checks for the last 15m. Click to show all Transaction checks."
                  href={this.props.servicesURL + '?monitoring_service_type=TRANSACTION'}
                >
                  <i />
                  Transaction
                </a>
                {mt.response_time.TRANSACTION.response_time_avg !== null ? (
                  // eslint-disable-next-line max-len
                  <a
                    title="Response time of Transaction checks for the last 15m. Click to show all Transaction checks."
                    href={this.props.servicesURL + '?monitoring_service_type=TRANSACTION'}
                  >
                    {Formatter.autoDuration(mt.response_time.TRANSACTION.response_time_avg)}
                  </a>
                ) : (
                  na
                )}
              </div>
              <div
                className={ReactUtils.cssClass(
                  'metric',
                  Formatter.responseTimeCSSClass(
                    mt.response_time.API.response_time_avg,
                    mt.response_time.API.response_time_sla
                  )
                )}
              >
                <a
                  title="Response time of API checks for the last 15m. Click to show all API checks."
                  href={this.props.servicesURL + '?monitoring_service_type=API'}
                >
                  <i />
                  API
                </a>
                {mt.response_time.API.response_time_avg !== null ? (
                  // eslint-disable-next-line max-len
                  <a
                    title="Response time of API checks for the last 15m. Click to show all API checks."
                    href={this.props.servicesURL + '?monitoring_service_type=API'}
                  >
                    {Formatter.autoDuration(mt.response_time.API.response_time_avg)}
                  </a>
                ) : (
                  na
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="p-4">
              <h6>Other</h6>
              <div
                className={ReactUtils.cssClass(
                  'metric',
                  Formatter.uptimePercentageCSSClass(mt.uptime.uptime_pct, mt.uptime.uptime_sla)
                )}
              >
                <span title="Average uptime of checks for the last 24h">
                  <i />
                  Global Uptime
                </span>
                {mt.uptime.uptime_pct !== null ? (
                  <span title="Average uptime of checks for the last 24h">
                    {Formatter.percentage(mt.uptime.uptime_pct)}
                  </span>
                ) : (
                  na
                )}
              </div>
              <div className={ReactUtils.cssClass('metric', {danger: moment.utc().diff(mt.outages.last, 'hours') < 2})}>
                <a title="Time since last alert" href={this.props.alertHistoryURL}>
                  <i />
                  Last Alert
                </a>
                {mt.outages.last !== null ? (
                  <a title="Time since last alert" href={this.props.alertHistoryURL}>
                    {Formatter.shortDuration(moment.utc().diff(mt.outages.last, 'seconds'))}
                  </a>
                ) : (
                  na
                )}
              </div>
              <div
                className={ReactUtils.cssClass(
                  'metric',
                  Formatter.rumLoadTimeCSSClass(mt.response_time.RUM.response_time_avg)
                )}
              >
                <a
                  title="Load time of RUM checks for the current day. Click to show all RUM checks."
                  href={this.props.rumReportsURL}
                >
                  <i />
                  RUM Load Time
                </a>
                {mt.response_time.RUM.response_time_avg !== null ? (
                  // eslint-disable-next-line max-len
                  <a
                    title="Load time of RUM checks for the current day. Click to show all RUM checks."
                    href={this.props.rumReportsURL}
                  >
                    {Formatter.autoDuration(mt.response_time.RUM.response_time_avg)}
                  </a>
                ) : (
                  na
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChecks() {
    const hasLoaded = this.props.hasLoaded;
    const hasChecks = this.props.serviceInfo.length > 0;

    return (
      <div id="dashboard-checks" className="mt-5 mb-n3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="font-weight-bold my-0 mr-2">Checks</h3>
          <div>
            {this.props.hasWriteAccess ? (
              <a title="Create a new check" href={this.props.servicesURL + '?pop=true'} className="btn btn-white">
                Add New
              </a>
            ) : null}
          </div>
        </div>
        <div className="row">
          {!hasLoaded ? <div className="col">Loading...</div> : null}
          {hasLoaded && !hasChecks ? <div className="col">No checks to display.</div> : null}
          {hasLoaded && hasChecks ? this.props.serviceInfo.map(this.renderCheck.bind(this)) : null}
        </div>
        <div className={ReactUtils.cssClass('mt-n3', {['d-none']: !hasLoaded || !hasChecks})}>
          <div className="d-flex flex-wrap justify-content-between">
            {!this.props.allServicesLoaded ? (
              <a href="" onClick={this.props.onShowMoreChecks} title="Show more checks">
                Show more...
              </a>
            ) : null}
            {this.props.autoRefreshIsSuppressed ? (
              <span>
                <em>auto-refresh has been temporarily suspended</em>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderCheck(svc) {
    const nameAndAddress = Formatter.serviceNameAndAddress(svc);
    const link = Formatter.serviceReportURL(svc, this.props.defaultDrillDownURL, this.props.drillDownURLs);
    const uptime24h = this.props.servicePerformanceMetrics[svc.id]['uptime_24h'];
    const responseTimeDatapoints = this.props.servicePerformanceMetrics[svc.id]['response_time_datapoints'];

    let checkStateCSS = 'up';
    if (svc.is_paused) {
      checkStateCSS = 'paused';
    } else if (!svc.cached_state_is_up) {
      checkStateCSS = 'down';
    }

    let checkState = '';
    if (svc.is_paused) {
      checkState = <p className="stat-value text-muted">Paused</p>;
    } else if (svc.cached_state_is_up) {
      const upDuration = moment.utc().diff(svc.cached_last_up_alert_at, 'seconds');
      checkState = <p className="stat-value text-success">Up for {Formatter.shortDuration(upDuration)}</p>;
    } else {
      const downDuration = moment.utc().diff(svc.cached_last_down_alert_at, 'seconds');
      checkState = <p className="stat-value text-danger">Down for {Formatter.shortDuration(downDuration)}</p>;
    }

    return (
      <div className="col-sm-6 col-lg-3 mb-4" key={svc.id}>
        <a className="check-card" href={link}>
          <div className={ReactUtils.cssClass('check-status-color', checkStateCSS)}></div>
          <div className="check-title">
            <div>
              <h6 title={nameAndAddress.name} className="truncate">
                {nameAndAddress.name}
              </h6>
              <p title={nameAndAddress.address} className="truncate">
                {nameAndAddress.address || <span>&nbsp;</span>}
              </p>
            </div>
            <div>
              {svc.is_under_maintenance ? (
                <i className="fas fa-fw fa-wrench fa-lg" title="Check is currently under maintenance." />
              ) : null}
            </div>
          </div>
          <div className="check-stats">
            <p className="stat-name">{svc.monitoring_service_type_display}</p>
            {checkState}
            {this.props.dashboardDef.services_show_uptime ? this.renderCheckUptime(svc, uptime24h) : null}
            {this.props.dashboardDef.services_show_response_time
              ? this.renderCheckResponseTime(
                  svc,
                  responseTimeDatapoints,
                  this.props.serviceDefs[svc.monitoring_service_type]
                )
              : null}
          </div>
        </a>
      </div>
    );
  }

  renderCheckUptime(svc, uptime24h) {
    if (svc.is_paused) {
      return null;
    }

    return (
      <React.Fragment>
        <p className="stat-name">Uptime (24h)</p>
        <p className={'stat-value text-' + Formatter.uptimePercentageCSSClass(uptime24h, svc.msp_uptime_sla)}>
          {Formatter.percentage(uptime24h)}
        </p>
      </React.Fragment>
    );
  }

  renderCheckResponseTime(svc, responseTimeDatapoints, serviceDef) {
    // If you're changing this method, please make sure to make corresponding changes
    // to `format_response_time` function in apps/lib/templatetags/uptime_tags.py
    let response = null;
    let responseCSS = '';
    let title = serviceDef.performance_data_type_display;

    if (svc.is_paused) {
      return response;
    }

    if (['RESPONSE_TIME', 'JITTER_DELTA', 'CUSTOM'].indexOf(serviceDef.performance_data_type) >= 0) {
      if (responseTimeDatapoints) {
        title += ' (1h)';
        response = <ResponseTimeChart responseTimeSLA={svc.msp_response_time_sla} data={responseTimeDatapoints} />;
      } else if (svc.cached_response_time >= 0.0) {
        response = Formatter.autoDuration(svc.cached_response_time);
        responseCSS = Formatter.responseTimeCSSClass(svc.cached_response_time, svc.msp_response_time_sla);
      }
    } else if (serviceDef.performance_data_type === 'RUM_LOAD_TIME' && responseTimeDatapoints) {
      title += ' (1d)';
      response = Formatter.autoDuration(responseTimeDatapoints[0][1]);
      responseCSS = Formatter.rumLoadTimeCSSClass(responseTimeDatapoints[0][1]);
    } else if (serviceDef.performance_data_type === 'DAYS_TO_EXPIRY' && svc.cached_response_time >= 1.0) {
      const days = svc.cached_response_time.toFixed(0);
      response = days + ' ' + Formatter.pluralize(days, 'day', 'days');
    } else if (svc.monitoring_service_type === 'PAGESPEED' && svc.cached_uptime_grade) {
      title = 'Uptime Grade';
      response = svc.cached_uptime_grade;
      responseCSS = Formatter.uptimeGradeCSSClass(svc.cached_uptime_grade);
    }

    if (response && typeof response === 'string') {
      response = <p className={ReactUtils.cssClass('stat-value', 'text-' + responseCSS)}>{response}</p>;
    }

    if (response === null) {
      return response;
    }

    return (
      <React.Fragment>
        <p className="stat-name">{title}</p>
        {response}
      </React.Fragment>
    );
  }

  renderLatestOutages() {
    return (
      <div id="dashboard-latest-outages" className="mt-5">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="font-weight-bold my-0">Latest Alerts</h3>
          <a href={this.props.alertHistoryURL} className="btn btn-white">
            View all
          </a>
        </div>
        <AlertHistoryList
          hasWriteAccess={false}
          detailsButtonOnly={true}
          hasLoaded={this.props.hasLoaded}
          analysisURL={this.props.analysisURL}
          onDetailsClick={this.props.onAlertDetailsClick}
          items={this.props.latestOutages}
        />
        <AlertDetailsModal
          ref="modal"
          alert={this.props.alertForDetails}
          alertExtDataURL={this.props.alertExtDataURL}
          editCheckURL={this.props.editCheckURL}
          analysisURL={this.props.analysisURL}
          hasWriteAccess={this.props.hasWriteAccess}
          stepDefs={this.props.step_defs}
        />
        {this.props.hasLoaded && !this.props.dashboardDef.alerts_include_resolved ? (
          <div
            className={ReactUtils.cssClass('text-muted', 'font-italic', {
              ['mt-n3']: this.props.latestOutages.length > 0,
            })}
          >
            Showing unresolved alerts from the last 30 outages that occurred.
          </div>
        ) : null}
      </div>
    );
  }
}
