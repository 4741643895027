import React, {useState} from 'react';
import SubscriptionDurationPicker from './SubscriptionDurationPicker';
import FeaturesTable from './FeaturesTable';
import Plan from './Plan';
import {CUSTOM_SECTIONS} from './CustomSections';
import {CUSTOM, ESSENTIAL, ON_DEMAND_FEATURES, PREMIUM, STARTER} from './PLAN_DEFS';
import OnDemandTable from './OnDemandTable';
import FullComparisonButton from './FullComparisonButton';
import CtaComparisonButtonGroup from './CtaComparisonButtonGroup';
import CtaBlurbComparisonButtonGroup from './CtaBlurbComparisonButtonGroup';

const ManageSubscription = ({
  plan,
  plans,
  checkFrequencies,
  starterLimits,
  bundleSizes,
  unitsPrices,
  isAnnual,
  onPlanChange,
  resetLocationsURL,
  unsupportedChecksURL,
}) => {
  const [showFeatures, setShowFeatures] = useState(false);
  const [showOnDemandFeatures, setShowOnDemandFeatures] = useState(false);
  const [featureText, setFeatureText] = useState(
    <>
      <span className="text-uppercase font-16 d-block">25% off w/ code</span>
      <span className="text-uppercase font-20 font-weight-bold d-block">SUMMERUP2024-Y</span>
    </>
  );

  const updateFeatureText = () => {
    const newFeatureText = isAnnual ? (
      <>
        <span className="text-uppercase font-16 d-block">25% off w/ code</span>
        <span className="text-uppercase font-20 font-weight-bold d-block">SUMMERUP2024-Y</span>
      </>
    ) : (
      <>
        <span className="text-uppercase font-16 d-block">25% off w/ code</span>
        <span className="text-uppercase font-20 font-weight-bold d-block">SUMMERUP2024-M</span>
      </>
    );
    setFeatureText(newFeatureText);
  };

  React.useEffect(() => {
    updateFeatureText();
  }, [isAnnual]);

  return (
    <>
      <div className="container">
        <CtaBlurbComparisonButtonGroup
          text_success="Upgrade Today"
          title_cont="and Unlock Enhanced Features from the Best Availability Monitoring Platform!"
          copy="Upgrade your subscription to one of the plans available below. Each plan is designed for scalability, enabling you to purchase additional add-ons after your initial purchase. For a customized plan with a distinct configuration, please reach out to us using the 'Contact Us' option. We look forward to addressing your monitoring needs no matter the size or scope."
          tooltip="You can’t change your billing period when upgrading your current plan."
          freeTrial_or_paid="paid"
          isAnnual={isAnnual}
        />
      </div>
      <div className="container mt-2">
        <div className="row PricingOptionWrapper">
          <Plan
            subtitle="Get started with monitoring necessities"
            name="STARTER"
            price={plans.STARTER.price}
            plan={plans.STARTER}
            disabled={plan === STARTER}
            onClick={() => onPlanChange(CUSTOM)}
            btnText={
              {
                STARTER: 'Current Plan',
                ESSENTIAL: 'Contact Us',
                PREMIUM: 'Contact Us',
                CUSTOM: 'Contact Us',
              }[plan]
            }
            resetLocationsURL={resetLocationsURL}
            unsupportedChecksURL={unsupportedChecksURL}
          />
          <Plan
            subtitle="Industry standard monitoring essentials"
            name="ESSENTIAL"
            price={plans.ESSENTIAL.price}
            plan={plans.ESSENTIAL}
            onClick={() => {
              const planToRequest = [PREMIUM, CUSTOM].indexOf(plan) !== -1 ? CUSTOM : ESSENTIAL;
              onPlanChange(planToRequest);
            }}
            disabled={plan === ESSENTIAL}
            btnText={
              {
                STARTER: 'Subscribe to Essential',
                ESSENTIAL: 'Current Plan',
                PREMIUM: 'Contact Us',
                CUSTOM: 'Contact Us',
              }[plan]
            }
            startHereText="Everything in Starter, and:"
            resetLocationsURL={resetLocationsURL}
            unsupportedChecksURL={unsupportedChecksURL}
          />
          <Plan
            name="PREMIUM"
            subtitle="The best plan for growing companies"
            onClick={() => {
              const planToRequest = [PREMIUM, CUSTOM].indexOf(plan) !== -1 ? CUSTOM : PREMIUM;
              onPlanChange(planToRequest);
            }}
            disabled={plan === PREMIUM}
            price={plans.PREMIUM.price}
            plan={plans.PREMIUM}
            btnText={
              {
                STARTER: 'Subscribe To Premium',
                ESSENTIAL: 'Subscribe To Premium',
                PREMIUM: 'Current Plan',
                CUSTOM: 'Contact Us',
              }[plan]
            }
            featured={true}
            startHereText="Everything in Essential, and:"
            resetLocationsURL={resetLocationsURL}
            unsupportedChecksURL={unsupportedChecksURL}
            featureText={featureText}
          />
          <Plan
            name="CUSTOM"
            sections={CUSTOM_SECTIONS}
            sectionsVisible={CUSTOM_SECTIONS.length}
            subtitle=""
            onClick={() => onPlanChange(CUSTOM)}
            startHereText="Custom Built for your Business!"
            btnText="Contact Us"
            resetLocationsURL={resetLocationsURL}
            unsupportedChecksURL={unsupportedChecksURL}
          />
        </div>
      </div>
    </>
  );
};

export default ManageSubscription;
