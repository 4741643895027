import React from 'react';
import FormBlock from './manage_addons/FormBlock';
import Summary from './manage_addons/Summary';
import ManageAddOnItem from './manage_addons/ManageAddOnItem';
import Banner from './manage_addons/Banner';
import {STARTER} from './PLAN_DEFS';

const ManageAddOns = ({
  isCustomer,
  sectionsDef,
  formLink,
  usedValues,
  includedValues,
  onValuesChange,
  unitsPrices,
  bundleSizes,
  accountType,
  isQuote,
  calculatedValues,
  commonDataFormLink,
  accountCanPurchase,
  userCanPurchase,
  handleGoToCheckout,
  accountUsageURL,
  limits,
  plan,
  pastDue,
}) => {
  const validationErrors = commonDataFormLink.errors('__all__');
  const cutLast2 = plan === STARTER;
  return (
    <div className="SubscriptionCustomizePage">
      <h1 className="heading">Manage Add-Ons</h1>
      {validationErrors && validationErrors.length && (
        <div className="alert alert-warning mt-5">{validationErrors.join(' ').trim()}</div>
      )}
      <Banner className="mt-5" accountUsageURL={accountUsageURL} />
      <div className="mb-6">
        <div className="mb-6">
          {sectionsDef.map((section, sectionId) => (
            <div key={'section-wrapper-' + sectionId}>
              {!(cutLast2 && sectionId > 1) && (
                <FormBlock className={'mt-4'} title={section.title} subtitle={section.subtitle}>
                  {section.items.map((item) => (
                    <ManageAddOnItem
                      key={`addon-${item.title}`}
                      item={item}
                      limit={limits[item.field] && limits[item.field]}
                      formLink={formLink}
                      usedValues={usedValues}
                      includedValues={includedValues}
                      unitsPrices={unitsPrices}
                      bundleSizes={bundleSizes}
                      currentPlan={plan}
                      nextPlan="Premium"
                      onValuesChange={onValuesChange}
                    />
                  ))}
                </FormBlock>
              )}
            </div>
          ))}
          <Summary
            pastDue={pastDue}
            price={calculatedValues.added_cost_full}
            priceBase={calculatedValues.base_price}
            priceDiscounted={calculatedValues.added_cost}
            isQuote={isQuote}
            isInvoiced={accountType === 'INVOICED'}
            calculatedValues={calculatedValues}
            isCustomer={isCustomer}
            accountCanPurchase={accountCanPurchase}
            userCanPurchase={userCanPurchase}
            handleGoToCheckout={handleGoToCheckout}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageAddOns;
