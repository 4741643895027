'use strict';

import React from 'react';
import URLHistory from '../general/URLHistory';
import Cookie from '../general/Cookie';
import Utils from '../general/Utils';
import ReactUtils from '../react/ReactUtils';

const INTERVALS = [0, 2, 5, 10, 15, 30, 60];

export default class AutoRefresh extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);

    const currentInterval = this._readAutoRefreshCookie() || 0;

    this.timerId = null;

    this.state = {
      autoRefreshInterval: currentInterval,
      timeUntilNextRefresh: currentInterval * 60,
    };
  }

  componentDidMount() {
    this.timerId = setInterval(this.handleTimerTick, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  isAutoRefreshActive() {
    return this.state.autoRefreshInterval > 0;
  }

  setRefreshInterval(interval, temporary) {
    if (!temporary) {
      this._writeAutoRefreshCookie(interval);
    }

    this.setState({
      autoRefreshInterval: interval,
      timeUntilNextRefresh: interval * 60,
    });
  }

  _getCurrentUrl() {
    return URLHistory.currentURL().split(/[?#]/)[0];
  }

  _decodeAutoRefreshCookie() {
    var cookieVal = Cookie.getCookie('uptime_auto_refresh');

    if (!cookieVal) {
      return null;
    }

    try {
      cookieVal = JSON.parse(cookieVal);
    } catch (e) {
      return null;
    }

    return cookieVal;
  }

  _readAutoRefreshCookie() {
    var cookieVal = this._decodeAutoRefreshCookie();
    if (!cookieVal) {
      return null;
    }

    var url = this._getCurrentUrl();
    return cookieVal[url] || null;
  }

  _writeAutoRefreshCookie(interval) {
    var url = this._getCurrentUrl();
    var cookieObj = this._decodeAutoRefreshCookie() || {};
    cookieObj[url] = interval;
    Cookie.setCookie('uptime_auto_refresh', JSON.stringify(cookieObj), 90);
  }

  handleIntervalSelection(e) {
    e.preventDefault();
    const interval = parseInt(e.target.dataset.interval) || 0;
    this.setRefreshInterval(interval);

    if (this.props.onAutoRefreshChanged) {
      this.props.onAutoRefreshChanged(interval);
    }
  }

  handleTimerTick() {
    const newTimeUntilNextRefresh = Math.max(0, this.state.timeUntilNextRefresh - 5);
    this.setState({timeUntilNextRefresh: newTimeUntilNextRefresh});

    if (newTimeUntilNextRefresh <= 0 && this.state.autoRefreshInterval > 0 && document.visibilityState === 'visible') {
      this.handleRefreshNow();
    }
  }

  handleRefreshNow() {
    this.setState({timeUntilNextRefresh: this.state.autoRefreshInterval * 60});
    this.props.refreshFunction();
  }

  render() {
    const buttons = INTERVALS.map((interval) => {
      const label = interval > 0 ? interval + ' mins' : 'Off';
      const cssClass = ReactUtils.cssClass('dropdown-item', {selected: interval == this.state.autoRefreshInterval});
      return (
        <button
          key={interval}
          data-interval={interval}
          onClick={this.handleIntervalSelection}
          className={cssClass}
          type="button"
        >
          <i className="fas fa-fw fa-check fa-sm" />
          {label}
        </button>
      );
    });

    const timeToRefreshPct =
      this.state.autoRefreshInterval > 0
        ? (this.state.timeUntilNextRefresh / (this.state.autoRefreshInterval * 60)) * 100.0
        : 0;

    return (
      <div className={ReactUtils.cssClass('btn-group position-relative', this.props.extraClass)}>
        <button
          type="button"
          title="Auto-Refresh"
          className="btn btn-white"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-fw fa-sync-alt" />
        </button>
        <div className="dropdown-menu dropdown-menu-right dropdown-menu-with-selections">
          <h6 className="dropdown-header">Auto-Refresh</h6>
          <div className="dropdown-divider"></div>
          {buttons}
          <div className="dropdown-divider"></div>
          <button onClick={this.handleRefreshNow} className="dropdown-item" type="button">
            Refresh Now
          </button>
        </div>
        <div
          className="position-absolute"
          style={{
            zIndex: 1,
            left: 0,
            bottom: 0,
            borderRadius: '0 0 3px 3px',
            height: '2px',
            backgroundColor: '#0074ce',
            right: 100.0 - timeToRefreshPct + '%',
          }}
        />
      </div>
    );
  }
}

AutoRefresh.defaultProps = {
  refreshFunction: null, // The function to be called when a refresh is triggered.
  onAutoRefreshChanged: null, // Callback when the refresh interval has changed.
  extraClass: '', // Additional CSS classes on the parent
};
