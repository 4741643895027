import React from 'react';
import _ from 'underscore';
import Cookie from '../../jskit/general/Cookie';
import Ajax from '../../jskit/general/Ajax';
import {HelpIcon, prepareFormLink} from '../../jskit/react/forms/FormHelpers';
import SubscriptionCheckoutPage from '../subscription/SubscriptionCheckoutPage';
import {AlertNotification} from './AlertNotification';
import SubscriptionDurationPicker from './SubscriptionDurationPicker';
import Plan from './Plan';
import SubscriptionHeader from './SubscriptionHeader';
import ContactUs from '../subscription/ContactUs';
import {ToastContainer} from 'react-toastify';
import CtaBlurbComparisonButtonGroup from './CtaBlurbComparisonButtonGroup';

const SubscriptionPage = {
  Configure: 'configure',
  Checkout: 'checkout',
};

const CUSTOM_PRICE_MONTHLY = 499;
const CUSTOM_PRICE_ANNUALLY = 399;

export default class SubscriptionFreeTrialController extends React.Component {
  constructor(props) {
    super(props);
    const minAddedValues = this._getMinAddedValues(this.props.minValues, this.props.includedValues);
    this.state = {
      monthlyPrices: {
        starter: 0,
        essential: 0,
        premium: 0,
      },
      featureText: (
        <>
          <span className="text-uppercase font-16 d-block">25% off w/ code</span>
          <span className="text-uppercase font-20 font-weight-bold d-block">SUMMERUP2024-Y</span>
        </>
      ),
      addOnsTableExpanded: false,
      customMonthlyPrice: CUSTOM_PRICE_ANNUALLY,
      page: SubscriptionPage.Configure,
      selectedPricingOption: '',
      customRequestInitialValues: this.props.companyData,
      addedValues: minAddedValues,
      commonData: {
        annual_billing: this.props.isAnnual,
      },
      validationErrors: {},
      checkoutErrors: null,
      calculated: {
        monthly_subtotal: 0.0,
        price_monthly: 0.0,
        base_price: 0.0,
        added_cost: 0.0,
        base_plan: '',
        discounts: [],
        units_prices: this.props.unitsPrices,
      },
      promoCodeError: undefined,
      minAddedValues: minAddedValues,
      includedValues: this.props.includedValues,
      checkoutAlertNotificationMessage: null,
      recaptchaRequired: false,
      recaptchaResponseToken: null,
    };

    const savedValues = Cookie.getJSONCookie('subscriptionPage');
    if (savedValues) {
      if (savedValues.addedValues) {
        this.state.addedValues = savedValues.addedValues;
      }
      if (savedValues.selectedPricingOption) {
        this.state.selectedPricingOption = savedValues.selectedPricingOption;
      }
      if (savedValues.commonData) {
        this.state.commonData = savedValues.commonData;
      }
    }
    this.throttledRecalculate = _.throttle(this.handleRecalculate, 200, {leading: false});
  }

  hasSelectedSomeAddonValues = (addedValues) => {
    for (var item in addedValues) {
      if (addedValues[item] > 0) {
        return true;
      }
    }
    return false;
  };

  toggleFeaturesTable() {
    this.setState({featuresTableExpanded: !this.state.featuresTableExpanded});
  }

  toggleAddOnsTable() {
    this.setState({addOnsTableExpanded: !this.state.addOnsTableExpanded});
  }

  checkForAdditionalValuesAdded = (selectedPricingOption) => {
    let notificationMessage = null;
    const includedValues = this.props.plans[selectedPricingOption].def;
    const minAddedValues = this._getMinAddedValues(this.props.minValues, includedValues, true);
    let newAddedValues = _.reduce(
      this.state.addedValues,
      (acc, v, k) => {
        acc[k] = _.isNumber(v) ? minAddedValues[k] : v;
        return acc;
      },
      {}
    );
    if (
      ['ESSENTIAL', 'STARTER'].indexOf(selectedPricingOption) !== -1 &&
      this.hasSelectedSomeAddonValues(newAddedValues)
    ) {
      // eslint-disable-next-line max-len
      notificationMessage =
        'We noticed you configured some extra addons. If you do not wish to purchase additional addons right now, simply remove them if no longer required or simply proceed to checkout.';
    }
    if (selectedPricingOption === 'STARTER') {
      // remove anything that goes beyond starter limit before passing to checkout
      const {starterLimits} = this.props;
      newAddedValues = _.reduce(
        newAddedValues,
        (acc, v, k) => {
          const maximumToAdd = Math.max(starterLimits[k] - this.props.plans.STARTER.def[k], 0);
          let valueToCount = Math.min(v, maximumToAdd);
          if (this.props.bundleSizes[k] > valueToCount) {
            // not possible to but if does not align with bundle size
            // this shouldn't happen
            valueToCount = 0;
            console.error('Misconfigured plan setup. Please contact support.');
          }
          acc[k] = valueToCount;
          return acc;
        },
        {}
      );
    }
    this.setState({
      checkoutAlertNotificationMessage: notificationMessage,
      addedValues: newAddedValues,
      includedValues,
    });
  };

  _getMinAddedValues = (minValues, includedValues, ignoreState = false) => {
    return _.mapObject(minValues, (v, k) => {
      const bundleSize = this.props.bundleSizes[k];
      const newVal = Math.ceil(Math.max(0, v - includedValues[k]) / bundleSize) * bundleSize;
      if (!ignoreState) {
        return Math.max(this.state ? this.state.addedValues[k] : 0.0, newVal);
      } else {
        return Math.max(0.0, newVal);
      }
    });
  };

  setSelectedPricingOption = (optionName) => {
    _.defer(() =>
      Cookie.setJSONCookie(
        'subscriptionPage',
        {
          addedValues: this.state.addedValues,
          selectedPricingOption: optionName,
          commonData: this.state.commonData,
        },
        1
      )
    );
    this.setState(
      {
        selectedPricingOption: optionName,
        page: SubscriptionPage.Checkout,
      },
      this.handleRecalculate
    );
    if (optionName !== 'CUSTOM') {
      this.checkForAdditionalValuesAdded(optionName);
    }
  };

  handleCancel = (e) => {
    e.preventDefault();

    Cookie.clearCookie('subscriptionPage');
    this.setState({
      selectedPricingOption: '',
      page: SubscriptionPage.Configure,
      commonData: {...this.state.commonData, promoCode: undefined},
      promoCodeError: undefined,
    });
  };

  handleCustomRequestSubmit = () => {
    Cookie.clearCookie('subscriptionPage');
    window.location = this.props.doneURL + '?action=custom-request';
  };

  handleSubscriptionUpdate = (paymentData, paymentFieldsErrorFn, finalizeSubmitFn) => {
    this.setState({checkoutErrors: null, recaptchaRequired: false});
    const data = {
      payment_data: paymentData,
      added_values: this.getFormData(),
      annual_billing: this.state.commonData.annual_billing,
      base_preset: this.state.selectedPricingOption,
      promo_code: this.state.commonData.promoCode,
      recaptcha_response_token: this.state.recaptchaResponseToken,
    };
    new Ajax().post({
      url: this.props.updateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          Cookie.clearCookie('subscriptionPage');
          let doneUrl = this.props.doneURL + '?action=subscription';
          if (!this.props.isCustomer) {
            doneUrl += '&grmkplan=' + data.data.new_subscription_price;
          }
          window.location = doneUrl;
        } else {
          finalizeSubmitFn();
          if (data.fields.payment_fields) {
            paymentFieldsErrorFn(data.fields.payment_fields);
          }
          if (data.fields.__all__) {
            this.setState({
              checkoutErrors: data.fields.__all__,
              recaptchaRequired: data.fields.recaptcha_required || false,
              recaptchaResponseToken: null,
            });
          }
        }
      }.bind(this),
    });
  };

  getFormData = () => {
    // convert naturally boolean value to integer
    const addedValues = Object.assign({}, this.state.addedValues);
    const advancedMonitoringBundle = !!addedValues.advanced_monitoring_bundle;
    addedValues.advanced_monitoring_bundle = advancedMonitoringBundle ? 1 : 0;
    if (this.state.minAddedValues.advanced_monitoring_bundle || addedValues.advanced_monitoring_bundle === 0) {
      delete addedValues.advanced_monitoring_bundle;
    }
    return addedValues;
  };

  onNewData = (data) => {
    this.setState({calculated: data});
  };

  handleRecalculate = (promo) => {
    let currentPreset = 'PREMIUM';
    if (this.state.selectedPricingOption === 'ESSENTIAL') {
      currentPreset = 'ESSENTIAL';
    }
    if (this.state.selectedPricingOption === 'STARTER') {
      currentPreset = 'STARTER';
    }
    const data = {
      preset: currentPreset,
      added_values: this.getFormData(),
      extra_data: {
        ...this.state.commonData,
        promo_code: promo,
      },
    };
    this.setState({validationErrors: {}});
    new Ajax().post({
      url: this.props.calculateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!_.isEmpty(data.data)) {
          this.onNewData(data.data);
        }
        if (!data.success) {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  };

  fetchMonthlyPrice = (preset) => {
    const data = {
      preset: preset,
      added_values: {},
      extra_data: this.state.commonData,
    };
    new Ajax().post({
      url: this.props.calculateURL,
      data: data,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (!_.isEmpty(data.data)) {
          var currentPrices = this.state.monthlyPrices;
          if (preset === 'STARTER') {
            currentPrices.starter = data.data.price_monthly;
          } else if (preset === 'ESSENTIAL') {
            currentPrices.essential = data.data.price_monthly;
          } else {
            currentPrices.premium = data.data.price_monthly;
          }
          this.setState({monthlyPrices: currentPrices});
        }
        if (!data.success) {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  };

  fetchAllMonthlyPrices = () => {
    this.fetchMonthlyPrice('STARTER');
    this.fetchMonthlyPrice('ESSENTIAL');
    this.fetchMonthlyPrice('PREMIUM');
  };

  subscriptionDurationChangeHandler = () => {
    const currentCommonData = {...this.state.commonData};
    currentCommonData.annual_billing = !currentCommonData.annual_billing;
    const updatedCustomMonthlyPrice = currentCommonData.annual_billing ? CUSTOM_PRICE_ANNUALLY : CUSTOM_PRICE_MONTHLY;
    const updatedFeatureText = currentCommonData.annual_billing ? (
      <>
        <span className="text-uppercase font-16 d-block">25% off w/ code</span>
        <span className="text-uppercase font-20 font-weight-bold d-block">SUMMERUP2024-Y</span>
      </>
    ) : (
      <>
        <span className="text-uppercase font-16 d-block">25% off w/ code</span>
        <span className="text-uppercase font-20 font-weight-bold d-block">SUMMERUP2024-M</span>
      </>
    );
    this.setState(
      {commonData: currentCommonData, customMonthlyPrice: updatedCustomMonthlyPrice, featureText: updatedFeatureText},
      () => {
        this.fetchAllMonthlyPrices();
        this.handleRecalculate();
      }
    );
  };

  createAlertMessage = () => {
    return (
      <React.Fragment>
        We noticed you were trying some of our advanced features. Please note that{' '}
        <a href={this.props.ssoURL} target="_blank">
          SSO
        </a>
        &nbsp;and Pagespeed Daily Check Frequency are available starting from Essential plan,{' '}
        <a href={`${this.props.uptimeBrandInfo.nextgenURL}${this.props.spURL}`} target="_blank">
          Status Page Customization
        </a>
        &nbsp;and 1-minute API Check Frequencies are available in Premium plan only. Please disable these features to
        subscribe to Starter or choose a higher level plan.
      </React.Fragment>
    );
  };

  renderCheckoutPage = () => {
    const commonDataFormLink = prepareFormLink(this, 'commonData', this.state.validationErrors);
    return (
      <React.Fragment>
        <SubscriptionDurationPicker
          className="position-relative width-fit m-auto p-4 align-items-center"
          isAnnual={this.state.commonData.annual_billing}
          onChange={this.subscriptionDurationChangeHandler}
        />
        <SubscriptionCheckoutPage
          unitsPrices={this.state.calculated.units_prices}
          bareUnitesPrices={this.props.unitsPrices}
          addedValues={this.state.addedValues}
          includedValues={this.state.includedValues}
          calculatedValues={this.state.calculated}
          formLink={commonDataFormLink}
          braintreeToken={this.props.braintreeToken}
          isCustomer={this.props.isCustomer}
          isUpgrade={false}
          companyData={this.props.companyData}
          allowPromocode={this.props.allow_promocode}
          promoCodeError={this.state.validationErrors['promo_code']}
          onPromocodeRefresh={({code}) => {
            this.handleRecalculate(code);
            this.state.commonData = {
              ...this.state.commonData,
              promoCode: code,
            };
          }}
          checkoutErrors={this.state.checkoutErrors}
          paymentMethod={this.props.paymentMethod}
          billingInfo={this.props.billingInfo}
          uptimeBrandInfo={this.props.uptimeBrandInfo}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubscriptionUpdate}
          updateURL={this.props.updateURL}
          alertNotificationMessage={this.state.checkoutAlertNotificationMessage}
          allCountries={this.props.allCountries}
          excludeStateCountries={this.props.excludeStateCountries}
          showStateDropdownForCountries={this.props.showStateDropdownForCountries}
          canPurchase={true}
          recaptchaSiteKey={this.props.recaptchaSiteKey}
          recaptchaRequired={this.state.recaptchaRequired}
          recaptchaSolved={this.state.recaptchaResponseToken !== null}
          recaptchaCallback={(token) => {
            this.setState({recaptchaResponseToken: token});
          }}
        />
      </React.Fragment>
    );
  };

  renderQuoteStrip = () => {
    if (this.state.selectedPricingOption === 'CUSTOM') {
      return false;
    }
    return (
      <div className="QuoteStrip position-sticky text-center">
        <button className="btn btn-success mr-2" onClick={() => this.setSelectedPricingOption('CUSTOM')}>
          Get Quote
        </button>
        <HelpIcon titleText="Click Get Quote to send a request to our Customer Support team to quickly assist you with your purchase." />
      </div>
    );
  };

  componentDidMount() {
    this.fetchAllMonthlyPrices();
    this.handleRecalculate();
  }

  getSubtitle(plan) {
    if (['STARTER', 'ESSENTIAL', 'PREMIUM'].indexOf(plan) !== -1) {
      return 'Checkout';
    }

    if (plan === 'CUSTOM') {
      return 'Custom Quote Request';
    }
  }

  render() {
    return (
      <>
        <SubscriptionHeader
          key={this.state.selectedPricingOption}
          title="Subscribe"
          subtitle={this.getSubtitle(this.state.selectedPricingOption)}
        />
        <ToastContainer />
        {this.state.page === SubscriptionPage.Configure && (
          <React.Fragment>
            <CtaBlurbComparisonButtonGroup
              text_success="Subscribe Now"
              title_cont="to the Best Availability Monitoring Platform!"
              copy="Choose from one of our pre-configured plans to kickstart your monitoring experience effortlessly! Each plan is designed for scalability, enabling you to purchase additional add-ons after your initial purchase. For a customized plan with a distinct configuration, please reach out to us using the 'Contact Us' option. We look forward to addressing your monitoring needs no matter the size or scope."
              freeTrial_or_paid="free"
              isAnnual={this.state.commonData.annual_billing}
              onChange={this.subscriptionDurationChangeHandler}
            />
            {this.props.hasActivePremiumFeatures && <AlertNotification message={this.createAlertMessage()} />}
            <div className="container mt-2">
              <div className="row PricingOptionWrapper">
                <Plan
                  subtitle="Start with basic monitoring necessities"
                  price={this.state.monthlyPrices.starter}
                  onClick={() => this.setSelectedPricingOption('STARTER')}
                  disabled={!this.props.isPrimary}
                  overLimit={!_.isEmpty(this.props.plans.STARTER.limits)}
                  plan={this.props.plans.STARTER}
                  name="STARTER"
                  btnText={'Subscribe to Starter'}
                  resetLocationsURL={this.props.resetLocationsURL}
                  unsupportedChecksURL={this.props.unsupportedChecksURL}
                />
                <Plan
                  subtitle="Industry standard monitoring essentials"
                  price={this.state.monthlyPrices.essential}
                  onClick={() => this.setSelectedPricingOption('ESSENTIAL')}
                  disabled={!this.props.isPrimary}
                  overLimit={!_.isEmpty(this.props.plans.ESSENTIAL.limits)}
                  plan={this.props.plans.ESSENTIAL}
                  name="ESSENTIAL"
                  btnText={'Subscribe to Essential'}
                  startHereText="Everything in Starter, and:"
                  resetLocationsURL={this.props.resetLocationsURL}
                  unsupportedChecksURL={this.props.unsupportedChecksURL}
                />
                <Plan
                  subtitle="The best plan for growing companies"
                  onClick={() => this.setSelectedPricingOption('PREMIUM')}
                  disabled={!this.props.isPrimary}
                  overLimit={!_.isEmpty(this.props.plans.PREMIUM.limits)}
                  price={this.state.monthlyPrices.premium}
                  plan={this.props.plans.PREMIUM}
                  name="PREMIUM"
                  btnText={'Subscribe to Premium'}
                  featured={true}
                  startHereText="Everything in Essential, and:"
                  resetLocationsURL={this.props.resetLocationsURL}
                  unsupportedChecksURL={this.props.unsupportedChecksURL}
                  featureText={this.state.featureText}
                />
                <Plan
                  name="CUSTOM"
                  onClick={() => this.setSelectedPricingOption('CUSTOM')}
                  subtitle=""
                  price={this.state.customMonthlyPrice}
                  btnText="Contact Us"
                  startHereText="Custom Built for your Business!"
                  disabled={!this.props.isPrimary}
                  resetLocationsURL={this.props.resetLocationsURL}
                  unsupportedChecksURL={this.props.unsupportedChecksURL}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.selectedPricingOption !== 'CUSTOM' &&
          this.state.page === SubscriptionPage.Checkout &&
          this.renderCheckoutPage()}
        {this.state.selectedPricingOption === 'CUSTOM' && (
          <ContactUs
            className="mt-5"
            initialValues={this.state.customRequestInitialValues}
            requestURL={this.props.customRequestURL}
            onCancel={this.handleCancel}
            onSuccess={this.handleCustomRequestSubmit}
          />
        )}
        {!this.props.isPrimary && this.renderQuoteStrip()}
      </>
    );
  }
}
