import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/CurrentStatus/CurrentStatus';
import IncidentsList from '@/status_pages/display/IncidentsList';
import {EmptySection} from '@/status_pages/display/EmptySection';

export interface ScheduledMaintenanceSectionProps {
  currentSectionId: CurrentStatusTabs;
  componentStatusRank: any;
  incidents: any;
}

export const ScheduledMaintenanceSection = ({
  currentSectionId,
  componentStatusRank,
  incidents,
}: ScheduledMaintenanceSectionProps) => (
  <SectionNavContent sectionId={CurrentStatusTabs.SCHEDULED_MAINTENANCE} currentSectionId={currentSectionId}>
    {incidents?.length > 0 ? (
      <IncidentsList componentStatusRank={componentStatusRank} incidents={incidents} />
    ) : (
      <EmptySection>
        There is no <strong>Scheduled Maintenance</strong>
      </EmptySection>
    )}
  </SectionNavContent>
);
