import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/CurrentStatus/CurrentStatus';
import IncidentsList from '@/status_pages/display/IncidentsList';
import ComponentStatus from '@/status_pages/display/ComponentStatus';
import SystemMetrics from '@/status_pages/display/SystemMetrics';
import {EmptySection} from '@/status_pages/display/EmptySection';

export interface StatusOverviewSectionProps {
  currentSectionId: CurrentStatusTabs;
  statuspage: any;
  componentStatusRank: any;
  componentStatusChoices: any;
  metricDatapointsURL: string;
}

export const StatusOverviewSection = ({
  currentSectionId,
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  metricDatapointsURL,
}: StatusOverviewSectionProps) => (
  <SectionNavContent sectionId={CurrentStatusTabs.STATUS_OVERVIEW} currentSectionId={currentSectionId}>
    {statuspage.show_active_incidents && (
      <>
        <h4 className="mb-3">All Events</h4>
        {statuspage.active_incidents.length > 0 ? (
          <IncidentsList componentStatusRank={componentStatusRank} incidents={statuspage.active_incidents} />
        ) : (
          <EmptySection>
            There are no <strong>Active Events</strong>
          </EmptySection>
        )}
        <div className="white-block-inner-divider mx-n4 my-4" />
      </>
    )}
    <h4 className="mb-3">Components: Current Status</h4>
    {statuspage.components?.length > 0 ? (
      <ComponentStatus
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
      />
    ) : (
      <EmptySection>
        There are no <strong>Components</strong>
      </EmptySection>
    )}
    <div className="white-block-inner-divider mx-n4 my-4" />
    <h4>System Metrics</h4>
    {statuspage.metrics?.length > 0 ? (
      <SystemMetrics metrics={statuspage.metrics} datapointsURL={metricDatapointsURL} />
    ) : (
      <EmptySection>
        There are no <strong>System Metrics</strong>
      </EmptySection>
    )}
  </SectionNavContent>
);
