'use strict';

import React from 'react';
import {uniqueHtmlId, unpackFormLinkOrProps, Label, FieldErrors, FieldHelpText} from './FormHelpers';
import ReactUtils from '../../../jskit/react/ReactUtils';

export default class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.htmlId = uniqueHtmlId(props.fieldName);
  }

  focus() {
    this.refs.textInput.focus();
  }

  render() {
    const form = unpackFormLinkOrProps(this.props);

    return (
      <div className={this.props.formGroupClass}>
        <Label fieldId={this.htmlId} {...this.props} />
        <textarea
          ref="textInput"
          id={this.htmlId}
          name={form.fieldName}
          className={ReactUtils.cssClass('form-control', {
            ['form-control-sm']: this.props.small,
            ['is-invalid']: form.errors,
          })}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
          rows={this.props.rows}
          value={form.value}
          onChange={form.onChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
        {this.props.addonLabel ? (
          <button className="btn btn-secondary mt-1" onClick={this.props.onAddonClick}>
            {this.props.addonLabel}
          </button>
        ) : null}
        <FieldErrors errors={form.errors} />
        <FieldHelpText {...this.props} />
      </div>
    );
  }
}

TextArea.defaultProps = {
  formGroupClass: 'form-group', // CSS classes for the form-group div
  fieldName: null, // Field name returned in form data
  labelText: null, // Label of this field
  titleText: null, // Tooltip
  helpText: null, // Help text beneath the field
  placeholder: null, // Placeholder text
  isRequired: false, // Field is required?
  disabled: false, // Control is disabled?
  readOnly: false, // Control is read only?
  rows: 3, // Text area rows
  small: false, // Control to be rendered in small font?
  maxLength: null, // HTML maxlength for this field
  addonLabel: null, // Label for an addon button at the end of the field

  formLink: null, // Object from prepareFormLink() to auto-bind value, onChange, formErrors
  value: null, // React field value
  formErrors: null, // Dict of all form errors, possibly with an entry for fieldName

  onChange: null, // Change handler
  onAddonClick: null, // Handler when addon button is clicked
  onFocus: null, // Focus handler
  onBlur: null, // Blur handler
};
