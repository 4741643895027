import React, {PropsWithChildren} from 'react';
import styles from './EmptySection.module.css';
import ReactUtils from '@/jskit/react/ReactUtils';

export interface EmptySectionProps extends PropsWithChildren {}

export const EmptySection = ({children}: EmptySectionProps) => {
  return (
    <div className="w-100 text-center">
      <i className={ReactUtils.cssClass('far fa-ban', styles.EmptySectionIcon)}></i>
      <p className={ReactUtils.cssClass('mt-3 mb-0', styles.EmptySectionMessage)}>{children}</p>
    </div>
  );
};
