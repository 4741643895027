import {SectionNavContent} from '@/jskit/react/SectionNav';
import {CurrentStatusTabs} from '@/status_pages/display/CurrentStatus/CurrentStatus';
import ComponentStatus from '@/status_pages/display/ComponentStatus';
import {EmptySection} from '@/status_pages/display/EmptySection';

export interface ComponentCurrentStatusSectionProps {
  currentSectionId: CurrentStatusTabs;
  statuspage: any;
  componentStatusRank: any;
  componentStatusChoices: any;
}

export const ComponentCurrentStatusSection = ({
  currentSectionId,
  statuspage,
  componentStatusRank,
  componentStatusChoices,
}: ComponentCurrentStatusSectionProps) => (
  <SectionNavContent sectionId={CurrentStatusTabs.COMPONENT_CURRENT_STATUS} currentSectionId={currentSectionId}>
    {statuspage.components?.length > 0 ? (
      <ComponentStatus
        statuspage={statuspage}
        componentStatusRank={componentStatusRank}
        componentStatusChoices={componentStatusChoices}
      />
    ) : (
      <EmptySection>
        There are no <strong>Components</strong>
      </EmptySection>
    )}
  </SectionNavContent>
);
